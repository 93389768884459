import './notification.scss';
import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconInfo} from '../../assets/icons/info-outline.svg';
import {ViewGridRow} from '../../view/grid/row.js';

const Notification = function(props){
    return <ViewGridRow>
        <div className={'zol-view-panel notification' + (props.className ? ' ' + props.className : '')}>
            <span className="notification-icon">
                <props.Icon/>
            </span>
            {props.children}
        </div>
    </ViewGridRow>;
};

Notification.propTypes = {
    className: PropTypes.string,
    Icon: PropTypes.elementType
};

Notification.defaultProps = {
    className: '',
    Icon: IconInfo
};

export {Notification};
