import React, {useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {AuthProtectionSecret} from '../../components/auth/protection-secret.js';
import {FeedbackError} from '../../components/feedback/error.js';
import './transactions.scss';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {TransactionsDetails} from './details.js';

function TransactionsExecute(props){
    const [wallet, setWallet] = useState(props.wallet);
    const [isExecuting, setIsExecuting] = useState(false);
    const [error, setError] = useState(null);

    const unlockWallet = async (protectionSecret) => {
        const wallet = await globalThis.Wallet.getWalletByAddress(
                props.transaction.addressFrom, protectionSecret);
        setWallet(wallet);
    };

    const executeTransaction = async (transaction) => {
        setIsExecuting(true);
        setError(null);
        try {
            // When the amount is in ether, we want to convert it to wei here.
            const value = '0x' + parseInt(transaction.amount * 1e18).toString(16);
            const tx = {
                to: transaction.addressTo,
                value
            };
            console.log('Transaction object:');
            console.log(tx);
            // getting the client
            const client = await globalThis.Wallet.client(transaction.chainId);
            wallet.connect(client);
            const popTx = await wallet.populateTransaction(tx);
            console.log('Populated transaction:');
            console.log(popTx);
            // increasing all gas fee options to help it move through. Depending how often we already tried we
            // increase it further from 10% to max 60%
            const tries = transaction.executions.length;
            const keys = ['gasLimit', 'gasPrice', 'maxPriorityFeePerGas', 'maxFeePerGas'];
            for(const key of keys){
                if(popTx.hasOwnProperty(key)){
                    popTx[key] = Math.round(popTx[key] * Math.pow(1.1, Math.max(tries + 1, 5)));
                }
            }
            // creating signed transaction
            const signedTx = await wallet.signTransaction(popTx);
            console.log('Signed transaction:');
            console.log(signedTx);
            // send through the backend api
            const result = await globalThis.TransactionManagementSgtsApi.executeTransaction(
                    transaction.id, signedTx, 'initial');
            console.log('Result from API:');
            console.log(result);

            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);

            // send directly through the frontend (only for testing if the transaction itself works).
            // const result = await client.sendTransaction(signedTx);
            // console.log('Result from API:');
            // console.log(result);
            // const receipt = result.wait();
            // console.log('Receipt:');
            // console.log(receipt);

            props.onClose();
        } catch (err){
            console.log(err);
            setError(err.message);
        } finally {
            setIsExecuting(false);
        }
    };

    useEffect(() => {
        if(wallet && ! isExecuting && ! error){
            executeTransaction(props.transaction);
        }
    }, [props.transaction, wallet, isExecuting, error]);

    if(! wallet){
        return <div>
            <TransactionsDetails transaction={props.transaction}/>
            <h2>Unlock wallet</h2>
            <p>Unlock the wallet for {props.transaction.buName} to execute this transaction.</p>
            <AuthProtectionSecret setSecret={unlockWallet} submitCaption="Unlock wallet"/>
        </div>;
    }

    const closeModal = () => {
        props.onClose();
        return;
    };

    return <div>
        {error ? <FeedbackError error={error}/> : null}
        {isExecuting ? <h2>Processing transaction....</h2> : null}
        {! isExecuting && ! error ? <h2>Transaction submitted for execution</h2> : null}

        <p className="center-align">
            <UIButtonConfirm onClick={closeModal}>
                Close window
            </UIButtonConfirm>
        </p>
    </div>;
}

TransactionsExecute.propTypes = {
    transaction: PropTypes.object.isRequired,
    wallet: PropTypes.object,
    onClose: PropTypes.func
};

TransactionsExecute.defaultProps = {
    wallet: null,
    onClose: () => {}
};

export {TransactionsExecute};
