import {getHash} from '../functions/get_hash.js';

class AsyncAction {
    constructor(properties = {}){
        this.listenerCurrentId = 0;
        this.listeners = {};
        this.properties = {};
        this.initialProperties = {};
        this.state = 'inactive';
        this.setProperties({
            onStart: () => {},
            onStop: () => {},
            onChange: () => {},
            ...properties
        });
    }

    isPropertyDifferent(key, value){
        const valueHash = getHash(value);
        if(! this.initialProperties.hasOwnProperty(key)){
            this.initialProperties[key] = valueHash;
            return true;
        }
        if(valueHash === this.initialProperties[key]){
            // eventhough we might have a different object, we do not consider it a change because the content
            // is the same.
            return false;
        }
        this.initialProperties[key] = valueHash;
        return true;
    }

    setProperties(properties){
        let hasUpdates = false;
        for(const key of Object.keys(properties)){
            if(key === 'callback'){
                if(this.state === 'running'){
                    if(this.properties[key]){
                        if(this.initialProperties[key] === properties[key]){
                            // same function, we can skip
                            continue;
                        }
                        // remove existig callback if present
                        this.removeListener(this.properties[key]);
                    }
                    // store the listener id in the properties
                    this.properties[key] = this.addListener(properties[key]);
                }
                this.initialProperties[key] = properties[key];
                if(this.isPropertyDifferent('callbackHash', properties[key])){
                    hasUpdates = true;
                }
            // We only update property values if they have changed since the last time they were set. Note
            // that values in this.properties might have changed due to internal updates inside the action.
            }else if(! this.initialProperties.hasOwnProperty(key) ||
                    properties[key] !== this.initialProperties[key]){
                if(this.isPropertyDifferent(key, properties[key])){
                    this.set(key, properties[key]);
                    hasUpdates = true;
                }else if(typeof properties[key] === 'function'){
                    // We need to update the reference though, cause references inside the function
                    // might have changed. Theoretically an object/array can also include funtions with the
                    // same problem, but we do also want to keep the same instance. Parsing recursively over
                    // the object to solve this is a bit too much for our purpose
                    this.set(key, properties[key]);
                }
            }
        }
        if(hasUpdates){
            if(this.state === 'running'){
                this.onChange(this);
            }
        }
    }

    set(key, value){
        this.properties[key] = value;
        // define getter and setter function for easy access
        if(! this.hasOwnProperty(key)){
            Object.defineProperty(this, key, {
                get(){
                    return this.properties[key];
                },

                set(value){
                    this.properties[key] = value;
                    // this.onChange(this);
                }
            });
        }
    }

    get(key){
        return this.properties[key];
    }

    start(){
        if(this.initialProperties.callback){
            this.properties.callback = this.addListener(this.initialProperties.callback);
        }
        this.state = 'running';
        this.onStart(this);
    }

    stop(){
        if(this.state === 'running'){
            this.state = 'inactive';
            for(const id of Object.keys(this.listeners)){
                this.removeListener(id);
            }
            delete this.properties.callback;
            this.onStop(this);
        }
    }

    restart(){
        this.stop();
        this.start();
    }

    addListener(callback){
        this.listenerCurrentId++;
        this.listeners[this.listenerCurrentId] = callback;
        return this.listenerCurrentId;
    }

    removeListener(id){
        delete this.listeners[id];
    }

    trigger(){
        if(this.state !== 'running'){
            return;
        }
        for(const callback of Object.values(this.listeners)){
            callback(this);
        }
    }
}

export {AsyncAction};
