import React from 'react';
import {ViewGridRow} from '../../view/grid/row.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {UIButtonNew} from '../../components/ui/button/new.js';
import {UITable} from '../../components/ui/table/table.js';
import {NavigationLink} from '../../components/navigation/link.js';
import {useUser} from '../../model/use_user.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {UITagStatus} from '../../components/ui/tag/status.js';
import {UITagCopy} from '../../components/ui/tag/copy.js';

function CreditorsList(){
    const user = useUser();
    const method = ! user ?
            {} :
            (user.isSGTS ? 'PayeeManagementApi.sgtsListAllPayees' : 'PayeeManagementApi.buListPayees');
    const resultSet = useModelFeedback(ModelSgtsJs, {method});

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const creditors = resultSet.data ?
        resultSet.data.map((creditor) => {
            return {
                ...creditor,
                name: creditor.description
            };
        }) :
        [];

    const columns = [
        {
            id: 'name',
            title: 'Name',
            width: 2,
            value: (row) => (row),
            renderCell: (row) => {
                // Payee is identified by wallet, blockchain_id and bu_name...
                const linkId = [row.address, row.chainId, row.bu].join('||');
                return <NavigationLink payload={{id: linkId}}>{row.name}</NavigationLink>;
            }
        },
        {
            id: 'bu',
            title: 'Business unit',
            width: 2,
            value: (row) => (row.bu)
        },
        {
            id: 'address',
            title: 'Account',
            width: 1,
            value: (row) => (row.address || '-'),
            renderCell: (value) => (<UITagCopy truncateLength={12}>{value}</UITagCopy>)
        },
        {
            id: 'status',
            title: 'Status',
            width: 1,
            headerStyle: {textAlign: 'right'},
            value: (row) => (row.status || '-'),
            renderCell: (value) => (<UITagStatus status={value} type="creditor"/>)
        }
    ];

    return <ViewGridRow className="page-row">
        <UIButtonNew className="btn-right">New creditor</UIButtonNew>
        <h1>Creditors</h1>

        <UITable data={creditors} columns={columns}/>

    </ViewGridRow>;
}

export {CreditorsList};
