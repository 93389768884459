import React from 'react';
import {PageDashboard} from './page/dashboard.js';
import {PageTransactions} from './page/transactions/transactions.js';
import {PageUsers} from './page/users/users.js';
import {PageWallets} from './page/wallets/wallets.js';
import {PageCreditors} from './page/creditors/creditors.js';
import {PageLog} from './page/log/log.js';

const sitemap = [
    {
        id: 'home',
        title: 'Dashboard',
        component: <PageDashboard/>,
        hasAccess: (user) => (true)
    },
    {
        id: 'transactions',
        title: 'Transactions',
        component: <PageTransactions/>,
        hasAccess: (user) => (true)
    },
    {
        id: 'wallets',
        title: 'Wallets/Units',
        component: <PageWallets/>,
        hasAccess: (user) => (user?.isSGTS)
    },
    {
        id: 'creditors',
        title: 'Creditors',
        component: <PageCreditors/>,
        hasAccess: (user) => (true)
    },
    {
        id: 'users',
        title: 'Users',
        component: <PageUsers/>,
        hasAccess: (user) => (true)
    },
    {
        id: 'log',
        title: 'Event log',
        component: <PageLog/>,
        hasAccess: (user) => (true)
    }
];

export {sitemap};
