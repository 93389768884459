import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconDownload} from '../../../assets/icons/download-outline.svg';
import {UIButtonIcon} from './icon.js';

function UIButtonDownload(props){
    return <UIButtonIcon {...props} Icon={IconDownload}>
        {props.children}
    </UIButtonIcon>;
}

UIButtonDownload.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
};

export {UIButtonDownload};
