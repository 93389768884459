import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconArrowLeft} from '../../assets/icons/arrow-circle-left-outline.svg';
import {NavigationLink} from './link.js';

const NavigationBack = function(props){
    const linkProps = {
        ...props,
        className: 'navigation-back' + (props.className ? ' ' + props.className : '')
    };

    return <NavigationLink {...linkProps}>
        <IconArrowLeft/> Back
    </NavigationLink>;
};

NavigationBack.propTypes = {
    payload: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string
};

export {NavigationBack};
