import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {FeedbackError} from '../../../components/feedback/error.js';
import {useUser} from '../../../model/use_user.js';
import {AuthProtectionSecret} from '../../../components/auth/protection-secret.js';
import './keys.scss';
import {ModelSgtsJs} from '../../../model/sgts-js.js';
import {UIInput} from '../../../components/ui/input/input.js';

function UsersKeysVerify(props){
    const user = useUser();
    const [error, setError] = useState(null);
    const [masterKeyId, setMasterKeyId] = useState('');

    const signKey = async (protectedSecret) => {
        try {
            await globalThis.SGTSUser.addMasterKeySignature(user.email, protectedSecret, masterKeyId);
        } catch (err){
            console.log(err);
            setError('Error activating user. ' + err);
            return;
        }
        ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
        props.updateModal({isVisible: false});
    };

    return <div>
        <p>
            The SGTS admin has setup the master key for you and shared the key id. Please provide this id to
            continue and sign your master key.
            <br/><br/>
        </p>
        <strong>Master key id:</strong>
        <p>
            <UIInput name="masterKeyId" value={masterKeyId} onChange={setMasterKeyId}/>
        </p>
        <FeedbackError error={error}/>
        <AuthProtectionSecret submitCaption="Sign master key" setSecret={signKey}/>
    </div>;
}

UsersKeysVerify.propTypes = {
    updateModal: PropTypes.func.isRequired
};

export {UsersKeysVerify};
