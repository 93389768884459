import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconYubikey} from '../../../assets/icons/yubikey.svg';
import {UIButtonIcon} from './icon.js';

function UIButtonYubikey(props){
    const onClick = () => {
        if(props.onClick){
            props.onClick();
        }else{
            if(confirm('Please insert your YubiKey and click OK')){
                const key = [1, 2, 3, 4].map((i) => (Math.random() + 1).toString(36).substring(2)).join('');
                props.onChange(key);
            }
        }
    };

    return <UIButtonIcon onClick={onClick} Icon={IconYubikey} className={props.className}>
        {props.children || 'YubiKey'}
    </UIButtonIcon>;
}

UIButtonYubikey.propTypes = {
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string
};

export {UIButtonYubikey};
