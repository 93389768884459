import React from 'react';
import {PropTypes} from 'prop-types';
import {useStateContext} from '../../helpers/state-context.js';
import {useCreateUrl} from '../../helpers/url-state-context/use_create_url.js';
import './link.scss';

const NavigationLink = function(props){
    const [, dispatch] = useStateContext();
    const createUrl = useCreateUrl();
    const className = 'navigation-link' + (props.className ? ' ' + props.className : '');
    const onClick = (event) => {
        event.preventDefault();
        if(props.onClick){
            props.onClick();
        }else if(props.payload){
            // Note: for this to work all values in payload needs to be maintained in the same StateContext
            dispatch({type: 'setValues', value: props.payload});
        }
    };
    const url = props.payload ? createUrl(props.payload) : window.location.href;

    return <a href={url} onClick={onClick} className={className}>
        {props.children}
    </a>;
};

NavigationLink.propTypes = {
    payload: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string
};

export {NavigationLink};
