
const CONFIG = {
    application_root: window.application_root || '',
    static_root: window.application_root || '',
    api_endpoint: (window.sgtsEndpoint ? window.sgtsEndpoint + '/' : null) || window.application_root || '',
    login_url: window.login_url || '/login',
    etherscanApiEndpoints: window.etherscanApiEndpoints,
    etherscanApiKeys: window.etherscanApiKeys,
    // Time in seconds after which we are refreshing the data
    dataRefreshTime: window.dataRefreshTime || 10
};

// input and appove needs to match backend role enum
CONFIG.userRoles = {
    input: 'BU Inputter',
    approve: 'BU Approver',
    sgts_user: 'SGTS user',
    sgts_admin: 'SGTS admin'
};

// needs to match backend blockchain enum
CONFIG.blockchainIds = {
    1: 'Ethereum Mainnet',
    5: 'Ethereum Goerli',
    137: 'Polygon',
    80001: 'Polygon Mumbai'
};

CONFIG.tokenIdsForChain = {
    1: ['ETH'],
    5: ['ETH'],
    137: ['MATIC'],
    80001: ['MATIC']
};

CONFIG.tokenNameForChain = {
    1: 'Ether Mainnet',
    5: 'Ether Goerli',
    137: 'Matic Polygon',
    80001: 'Matic Mumbai'
};

export {CONFIG};
