import React from 'react';
import {PropTypes} from 'prop-types';

const TableCellDefault = function(props){
    const col = props.settings;

    return <td key={props.index}
            className={'zol-table-cell' +
                    (col.isFixed ? ' zol-table-cell-fixed' : '')}
            colSpan={props.colspan}
            style={{
                position: col.isFixed ? 'relative' : 'inherit',
                left: col.isFixed ? props.scrollOffset : '',
                width: col.width,
                wordBreak: 'break-word',
                ...(col.cellStyle || {})
            }}>
        {col.renderCell ?
            col.renderCell(col.value(props.rowData, props.rowIndex),
                    {...props.renderProps, data: props.rowData}) :
            col.value(props.rowData, props.rowIndex)
        }
    </td>;
};

TableCellDefault.propTypes = {
    index: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    settings: PropTypes.shape({
        isFixed: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        cellStyle: PropTypes.object,
        renderCell: PropTypes.func,
        value: PropTypes.func.isRequired
    }).isRequired,
    rowData: PropTypes.object.isRequired,
    scrollOffset: PropTypes.number,
    colspan: PropTypes.number,
    renderProps: PropTypes.object
};

TableCellDefault.defaultProps = {
    scrollOffset: 0,
    colspan: 1,
    renderProps: {}
};

export {TableCellDefault};
