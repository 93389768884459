import React from 'react';
/* react-dom/client is not a JS file, hence no extension */
/* eslint-disable-next-line */
import ReactDOM from 'react-dom/client';
import './index.scss';
import {App} from './App.js';
import {reportWebVitals} from './reportWebVitals.js';
import {UrlStateContext} from './helpers/url-state-context.js';

const initialState = {
    page: 'home',
    id: null,
    action: null
};

const root = ReactDOM.createRoot(document.getElementById('scw-dashboard'));
root.render(
    <React.StrictMode>
        <UrlStateContext hash={['page', 'id', 'action']} initialState={initialState}>
            <App/>
        </UrlStateContext>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
