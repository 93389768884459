import {useEffect, useCallback, useState} from 'react';
import {SimpleEvent} from '../simple-event.js';

const useClickOutsideElement = function(callback, rootElement = document.body, isActive = true){
    const [element, setElement] = useState(null);
    const measuredRef = useCallback((node) => {
        setTimeout(() => {
            setElement(node);
        }, 10);
    }, []);

    useEffect(() => {
        const onRootElementClick = (event) => {
            let node = event.target;
            if(element !== null && node !== element){
                while(node.parentNode && node.parentNode !== rootElement &&
                        node.parentNode !== document.body){
                    node = node.parentNode;
                    if(node === element){
                        // click inside the element, do not trigger the callback
                        return;
                    }
                }
                // element not found, so the click was outside the element. Apply the callback
                callback(event);
            }
        };

        const event = isActive ? new SimpleEvent(rootElement, 'click', onRootElementClick) : null;
        return () => {
            if(event){
                event.remove();
            }
        };
    }, [rootElement, callback, element, isActive]);

    return measuredRef;
};

export {useClickOutsideElement};
