import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {ViewGridRow} from '../../view/grid/row.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {NavigationBack} from '../../components/navigation/back.js';
import {UITable} from '../../components/ui/table/table.js';
import {NavigationLink} from '../../components/navigation/link.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {ViewGridCol} from '../../view/grid/col.js';
import {useUsers} from '../../model/use_users.js';
import {CONFIG} from '../../config.js';
import {NavigationBreadcrumb} from '../../components/navigation/breadcrumb.js';
import {useUser} from '../../model/use_user.js';
import {UITagCopy} from '../../components/ui/tag/copy.js';
import {UIModal} from '../../components/ui/modal/modal.js';
import {WalletsRecovery} from './recovery.js';
import {transactionsTableColumns} from '../transactions/list.js';
import {UITagStatus} from '../../components/ui/tag/status.js';
import {formatNumber} from '../../helpers/format/number.js';

function WalletsItem(props){
    const [isRecoveryModalOpen, setIsRecoveryModalOpen] = useState(false);
    // TODO: api is missing a function to get single item of Business unit
    const resultSetUnit = useModelFeedback(ModelSgtsJs, {method: 'BuManagementApi.listBusinessUnits'});
    const unit = resultSetUnit.feedback ?
            {} : resultSetUnit.data.find((unit) => (unit.name === props.id)) || {};

    const resultSetWallet = useModelFeedback(ModelSgtsJs,
            {method: 'WalletApi.getWalletByBu', params: [props.id]});
    const walletAddress = resultSetWallet.feedback ? null : resultSetWallet.data.walletAddress;

    const resultSetBalance = useModelFeedback(ModelSgtsJs,
        walletAddress ?
            {method: 'Wallet.getWalletContent', params: [walletAddress]} :
            {});
    const walletBalance = resultSetBalance.feedback ? [] : resultSetBalance.data;
    const walletTotalUsd = walletBalance.reduce((total, balance) => (total + balance.usd), 0);

    const userResult = useUsers((user) => (user.unit === props.id));
    const users = userResult.users;
    const feedbackUsers = userResult.feedback;
    const user = useUser();
    const resultSetCreditors = useModelFeedback(ModelSgtsJs,
            ! user ?
                {} :
                (user.isSGTS ?
                    {method: 'PayeeManagementApi.sgtsListBuPayees', params: [props.id]} :
                    {method: 'PayeeManagementApi.buListPayees'}
                ));
    const creditors = resultSetCreditors.data;

    const userColumns = [
        {
            id: 'name',
            title: 'Name',
            width: 2,
            value: (row) => (row),
            renderCell: (row) => (
                <NavigationLink payload={{page: 'users', id: row.name}}>{row.name}</NavigationLink>
            )
        },
        {
            id: 'role',
            title: 'Role',
            width: 1,
            value: (row) => (CONFIG.userRoles[row.role] || row.role)
        }
    ];

    const creditorsColumns = [
        {
            id: 'name',
            title: 'Name',
            width: 2,
            value: (row) => (row),
            renderCell: (row) => {
                // Payee is identified by wallet, blockchainId and bu_name...
                const id = [row.address, row.chainId, row.bu].join('||');
                return <NavigationLink payload={{page: 'creditors', id}}>
                    {row.description}
                </NavigationLink>;
            }
        },
        {
            id: 'status',
            title: 'Status',
            width: 1,
            headerStyle: {textAlign: 'right'},
            value: (row) => (row.status || '-'),
            renderCell: (value) => (<UITagStatus status={value} type="creditor"/>)
        }
    ];

    const walletColumns = [
        {
            id: 'currency',
            title: 'Currency',
            width: 2,
            value: (row) => (row.type === 'native' ? CONFIG.tokenNameForChain[row.chainId] : row.name)
        },
        {
            id: 'balance',
            title: 'Balance',
            width: 1,
            headerStyle: {textAlign: 'right'},
            cellStyle: {textAlign: 'right'},
            value: (row) => (
                formatNumber(row.type === 'native' ?
                        row.balance / Math.pow(10, 18) :
                        row.balance / Math.pow(10, row.decimals))
            ),
            renderCell: (value) => (formatNumber(value))
        },
        {
            id: 'usd',
            title: 'USD',
            width: 1,
            headerStyle: {textAlign: 'right'},
            cellStyle: {textAlign: 'right'},
            value: (row) => (row.usd || '-'),
            renderCell: (value) => (formatNumber(value))
        }
    ];

    const selectionCriteria = ! user ?
            {} :
            (user.isSGTS ?
                {method: 'TransactionManagementSgtsApi.listTransactions'} :
                {method: 'TransactionManagementBuApi.listTransactions'}
            );
    const resultSetTransaction = useModelFeedback(ModelSgtsJs, selectionCriteria);
    const transactions = resultSetTransaction.feedback ? [] : resultSetTransaction.data.filter((t) => (
        t.buName === props.id
    ));

    return <>
        <ViewGridRow className="page-row">
            <NavigationBack payload={{id: null, action: null}}/>
            <NavigationBreadcrumb path={[{title: 'Unit', payload: {id: null}}]}>
                {props.id}
            </NavigationBreadcrumb>

            <ViewPanel>
                <dl>
                    <dt>Name:</dt>
                    <dd>{props.id}</dd>
                    <dt>Branch</dt>
                    <dd>{unit.branch}</dd>
                    <dt>BP Number (SAP)</dt>
                    <dd>{unit.bpNumber}</dd>
                    <dt>Wallet value (USD)</dt>
                    <dd><strong>$ {formatNumber(walletTotalUsd, true)}</strong></dd>
                    <dt>Recovery</dt>
                    <dd>
                        <NavigationLink onClick={() => {setIsRecoveryModalOpen(true);}}>
                            Show recovery details
                        </NavigationLink>
                    </dd>
                </dl>
            </ViewPanel>
        </ViewGridRow>

        <ViewGridRow>
            <ViewGridCol width={33}>
                <ViewPanel className="panel-table">
                    {resultSetWallet.feedback ?
                        resultSetWallet.feedback :
                        <UITagCopy truncateLength={8} className="action">
                            {resultSetWallet.data.walletAddress}
                        </UITagCopy>
                    }
                    <h2>Wallet</h2>
                    <UITable data={walletBalance} columns={walletColumns}/>
                    {resultSetBalance.feedback}
                    {! resultSetBalance.feedback ?
                        <div className="wallet-total-usd">
                            <strong>Total USD</strong>
                            <span>{formatNumber(walletTotalUsd)}</span>
                        </div> :
                        null
                    }
                </ViewPanel>

                <ViewPanel className="panel-table">
                    <h2>Users</h2>
                    {feedbackUsers ?
                        feedbackUsers :
                        <UITable data={users} columns={userColumns}/>
                    }
                </ViewPanel>

                <ViewPanel className="panel-table">
                    <h2>Creditors</h2>
                    {resultSetCreditors.feedback ?
                        resultSetCreditors.feedback :
                        <UITable data={creditors} columns={creditorsColumns}/>
                    }
                </ViewPanel>
            </ViewGridCol>

            <ViewGridCol width={67}>
                <ViewPanel className="panel-table">
                    <h2>Transactions</h2>
                    {resultSetTransaction.feedback ?
                        resultSetTransaction.feedback :
                        <UITable data={transactions} columns={transactionsTableColumns}/>
                    }
                </ViewPanel>
            </ViewGridCol>
        </ViewGridRow>

        <UIModal visible={isRecoveryModalOpen} title="Recovery details" width={800} height={450}
                onClose={() => {setIsRecoveryModalOpen(false);}}>
            <WalletsRecovery unit={unit?.name || ''} onClose={() => {setIsRecoveryModalOpen(false);}}/>
        </UIModal>
    </>;
}

WalletsItem.propTypes = {
    id: PropTypes.string.isRequired
};

export {WalletsItem};
