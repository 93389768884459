import React from 'react';
import {ViewGridRow} from '../../view/grid/row.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {UIButtonNew} from '../../components/ui/button/new.js';
import {UITable} from '../../components/ui/table/table.js';
import {NavigationLink} from '../../components/navigation/link.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUser} from '../../model/use_user.js';

function WalletsList(){
    const resultSet = useModelFeedback(ModelSgtsJs, {method: 'BuManagementApi.listBusinessUnits'});
    const user = useUser();

    if(resultSet.feedback){
        return resultSet.feedback;
    }
    const units = resultSet.data;

    const columns = [
        {
            id: 'name',
            title: 'Business unit name',
            width: 2,
            value: (row) => (row),
            renderCell: (row) => (
                <NavigationLink payload={{id: row.name}}>{row.name}</NavigationLink>
            )
        },
        {
            id: 'branch',
            title: 'Branch',
            width: 1,
            value: (row) => (row.branch)
        },
        {
            id: 'users',
            title: 'Users',
            width: 2,
            value: (row) => (row.unit || '-')
        }
    ];

    const newWalletNote = user?.hasMasterKey ?
            null : 'You need to setup a signed Master key before you can create a new wallet';

    return <ViewGridRow className="page-row">
        <UIButtonNew className="btn-right" enabled={user?.hasMasterKey} title={newWalletNote}>
            New unit
        </UIButtonNew>
        <h1>Business units / wallets</h1>

        <UITable data={units} columns={columns}/>

    </ViewGridRow>;
}

export {WalletsList};
