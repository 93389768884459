import PropTypes from 'prop-types';
import React from 'react';
import './input.scss';

const UIInputItem = function(props){
    return <div className={'ui-input-item' + (props.hasError ? ' ui-input-error' : '')}>
        <span className="ui-input-label">{props.label}:</span>
        <span className="ui-input-field"><props.Component {...props}/></span>
    </div>;
};

UIInputItem.defaultProps = {
    placeholder: '',
    hasError: false
};

UIInputItem.propTypes = {
    name: PropTypes.string.isRequired,
    Component: PropTypes.elementType.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    hasError: PropTypes.bool
};

export {UIInputItem};
