import {useModel} from '../helpers/model/use_model.js';
import {ModelEtherscan} from './etherscan.js';
import {formatNumber} from '../helpers/format/number.js';
import {CONFIG} from '../config.js';

const useWalletBalance = function(address, chainId = 1, includeCurrency = false){
    const selectionCriteria = address ?
        {
            module: 'account',
            action: 'balance',
            address,
            tag: 'latest',
            chainId
        } :
        {};
    const resultSet = useModel(ModelEtherscan, selectionCriteria);
    if(! chainId){
        return '-';
    }
    const currency = CONFIG.tokenIdsForChain[chainId][0];
    if(resultSet.status === ModelEtherscan.Status.SUCCESS){
        return formatNumber(parseInt(resultSet.data) / 1e18, true, 4) +
                (includeCurrency ? ' ' + currency : '');
    }
    return resultSet.status === ModelEtherscan.Status.FAILED ? '-' : '...';
};

export {useWalletBalance};
