import React from 'react';
import PropTypes from 'prop-types';

const styleWarning = {
    position: 'relative',
    cssFloat: 'left',
    width: '80px',
    height: '80px',
    marginRight: '20px'
};
const styleBorder = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '0 40px 69px 40px',
    borderColor: 'transparent transparent #909090 transparent'
};
const styleFill = {
    position: 'absolute',
    width: '0',
    height: '0',
    top: '12px',
    left: '12px',
    borderStyle: 'solid',
    borderWidth: '0 28px 50px 28px',
    borderColor: 'transparent transparent white transparent'
};
const styleExclamation = {
    position: 'absolute',
    width: '80px',
    height: '50px',
    top: '15px',
    lineHeight: '50px',
    fontSize: '42px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#909090'
};

const ComponentError = function(props){
    return <div style={{clear: 'both'}}>
        <p>
            <span style={styleWarning}>
                <span style={styleBorder}/>
                <span style={styleFill}/>
                <span style={styleExclamation}>!</span>
            </span>
            {props.title || 'Unknown error'}
            <br/>
            {props.message || props.children || 'An unknown error occurred'}
        </p>
    </div>;
};

ComponentError.defaultProps = {
    title: 'Error',
    message: null
};

ComponentError.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
};

export {ComponentError};
