import React from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from './use_state_context.js';

/*  StateConsumer takes a function as a child, it sends two arguments, the (filtered) state
    and the dispatcher. To filter the state you can set the prop 'watch', which contains a
    single namespace or an array of namespaces, e.g.: watch={['page', 'item_id']}
    Note that when filtering the state with watch, any other parent state values become
    inaccessible by the child components in this context.
*/
const StateConsumer = function(props){
    const {watch, children, ...otherProps} = props;
    const [state, dispatcher] = useStateContext(watch);
    return <>
        {/*
            When the StateConsumer gets any props except the ones in proptypes it will
            send them to the child component.
        */}
        {(Object.keys(otherProps).length > 0) ?
            React.cloneElement(children(state, dispatcher), {...otherProps}) :
            children(state, dispatcher)}
    </>;
};

StateConsumer.defaultProps = {
    watch: null
};

StateConsumer.propTypes = {
    watch: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.func.isRequired
};

export {StateConsumer};
