import React from 'react';
import {PropTypes} from 'prop-types';
import {NavigationLink} from './link.js';
import {ReactComponent as IconEdit} from '../../assets/icons/edit-2-outline.svg';
import {ReactComponent as IconTrash} from '../../assets/icons/trash-2-outline.svg';
import {useStateContext} from '../../helpers/state-context.js';
import './link.scss';

const NavigationItemEdit = function(props){
    const [, dispatch] = useStateContext(['action', 'id']);
    const onDelete = () => {
        if(window.confirm('Are you sure you want to delete this item?')){
            dispatch({type: 'setValues', value: {action: 'delete', id: props.id}});
        }
    };
    return <div className="navigation-item-edit">
        {props.includeEdit ?
            <NavigationLink payload={{action: 'edit', id: props.id}}>
                <IconEdit/> Edit
            </NavigationLink> :
            null
        }
        {props.includeDelete ?
            <NavigationLink onClick={onDelete}>
                <IconTrash/> Delete
            </NavigationLink> :
            null
        }
    </div>;
};

NavigationItemEdit.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    includeEdit: PropTypes.bool,
    includeDelete: PropTypes.bool
};

NavigationItemEdit.defaultProps = {
    includeEdit: true,
    includeDelete: true
};

export {NavigationItemEdit};
