import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as IconUp} from './icons/up.svg';
import {ReactComponent as IconDown} from './icons/down.svg';
import colors from '../../../assets/variables.module.scss';

const UITableSort = function(props){
    const defaultSort = [
        {label: <IconUp/>, value: props.id + ' desc'},
        {label: <IconDown/>, value: props.id + ' asc'}
    ];

    const list = (props.sort === true) ? defaultSort : props.sort;
    return <span className="zol-table-header-sort">
        {list.map((sort) => {
            const isCurrent = (props.order === sort.value);
            return <span key={sort.value}
                    style={{
                        cursor: 'pointer',
                        margin: '-5px 0',
                        color: isCurrent ? '#fff' : colors.colorMedium
                    }}
                    onClick={() => {
                        if(! isCurrent || props.allowUnset){
                            props.onSelect(sort.value);
                        }
                    }}>
                {sort.label}
            </span>;
        })}
    </span>;
};

UITableSort.defaultSort = {
    allowUnset: true
};

UITableSort.propTypes = {
    id: PropTypes.string,
    sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    order: PropTypes.string,
    onSelect: PropTypes.func,
    // When true unsetting the sort order is allowed. Otherwise a sort order should always be present
    allowUnset: PropTypes.bool
};

export {UITableSort};
