import {useEffect, useState} from 'react';
import {AsyncAction} from './async-action.js';
import {getUniqueId} from '../functions/get_unique_id.js';

const asyncActions = {};
const useAsyncAction = function(properties){
    const [uid] = useState(getUniqueId(6));

    useEffect(() => {
        // update the properties of the action when they change
        // check if the action is still present. It's possably already deleted before this effect is called
        if(! asyncActions[uid]){
            // defining a new action
            asyncActions[uid] = new AsyncAction();
        }
        asyncActions[uid].setProperties(properties);
    }, [properties, uid]);

    useEffect(() => {
        // start the action when the component starts
        asyncActions[uid].start();
        return () => {
            asyncActions[uid].stop();
            delete asyncActions[uid];
        };
    }, [uid]);
};

export {useAsyncAction};
