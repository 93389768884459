import React from 'react';
import {PropTypes} from 'prop-types';
import {useStateContext} from '../../../helpers/state-context.js';
import {ReactComponent as IconPlusCircle} from '../../../assets/icons/plus-circle-outline.svg';
import {UIButtonIcon} from './icon.js';

function UIButtonNew(props){
    const [, dispatch] = useStateContext(['id', 'action']);
    const onClick = () => {
        if(! props.enabled){
            return;
        }
        if(props.onClick){
            props.onClick();
        }else{
            dispatch({type: 'action', value: 'new'});
        }
    };

    const className = props.className + (props.enabled ? '' : ' zol-ui-botton-disabled');

    return <UIButtonIcon onClick={onClick} Icon={IconPlusCircle} className={className} title={props.title}>
        {props.children}
    </UIButtonIcon>;
}

UIButtonNew.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    enabled: PropTypes.bool,
    title: PropTypes.string
};

UIButtonNew.defaultProps = {
    enabled: true,
    title: null
};

export {UIButtonNew};
