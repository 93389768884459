import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {NavigationBack} from '../../components/navigation/back.js';
import {ViewGridRow} from '../../view/grid/row.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {FeedbackError} from '../../components/feedback/error.js';
import {UIInputItem} from '../../components/ui/input/item.js';
import {UIInput} from '../../components/ui/input/input.js';
import {UIInputLabel} from '../../components/ui/input/label.js';
import {UIInputSelect} from '../../components/ui/input/select.js';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {useModel} from '../../helpers/model/use_model.js';
import {getModelResult} from '../../helpers/model/get_model_result.js';
import {CONFIG} from '../../config.js';
import {useStateContext} from '../../helpers/state-context.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUsers} from '../../model/use_users.js';
import {useUser} from '../../model/use_user.js';

let deletionDone = false;
function UsersEdit(props){
    const [{action}, dispatch] = useStateContext(['action']);
    const {users} = useUsers((user) => (user.email === props.id));
    const user = useUser();
    const selectedUser = users.length === 0 ? null : users[0];

    const [formValues, setFormValues] = useState({
        email: selectedUser?.email || '',
        role: selectedUser?.role || 'input',
        buName: selectedUser?.unit || ''
    });

    const deleteUser = async (user) => {
        // For some reason the we are stuck with old state values for just a short time, which causes the DOM
        // tree to end up here again, while we just removed the item. To prevent this we set a global variable
        // for now. Need to fix the issue in StateContext in the long run.
        deletionDone = true;
        setTimeout(() => {deletionDone = false;}, 500);
        // delete user and redirect back to list
        const method = selectedUser.isSGTS ?
                'SGTSUserManagementApi.deleteUser' : 'BuUserManagementApi.deleteBuUser';
        try {
            await getModelResult(ModelSgtsJs, {method, params: [selectedUser.email]});
            // status is success when at this point
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
        } catch (resultSetError){
            // error handling
            alert('Failed to delete user. ' + resultSetError.error);
        }
        dispatch({type: 'setValues', value: {action: null, id: null}});
    };
    useEffect(() => {
        if(selectedUser && action === 'edit' && formValues.email !== selectedUser?.email){
            setFormValues({
                email: selectedUser.email,
                role: selectedUser.role,
                buName: selectedUser.unit
            });
        }else if(selectedUser && action === 'delete' && deletionDone === false){
            deleteUser(selectedUser);
        }
    }, [selectedUser, action]);
    const [error, setError] = useState(null);

    // If we are editing a user, we cannot switch from SGTS role to BU role and visa versa
    const roles = Object.keys(CONFIG.userRoles).filter((role) => ((
        action === 'new' || (selectedUser?.isSGTS && role.substring(0, 4) === 'sgts') ||
                (! selectedUser?.isSGTS && role.substring(0, 4) !== 'sgts')
    ))).reduce((obj, role) => {
        // Only SGTS admin can assign SGTS roles
        if(user?.isAdmin || role.substring(0, 4) !== 'sgts'){
            obj[role] = CONFIG.userRoles[role];
        }
        return obj;
    }, {});

    const resultSet = useModel(ModelSgtsJs, {method: 'BuManagementApi.listBusinessUnits'});
    const units = resultSet.status === ModelSgtsJs.Status.SUCCESS ?
            resultSet.data.map((item) => (item.name)) : [];

    const saveUser = async () => {
        if(! formValues.email){
            setError('Please provide the e-mail address of the user');
            return;
        }else if(! formValues.email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)){
            setError('Please provide a valid e-mail address');
            return;
        }
        const selectionCriteria = {};
        if(formValues.role.substring(0, 4) === 'sgts'){
            // Create or edit sgts user
            selectionCriteria.method = 'SGTSUserManagementApi.' +
                    (action === 'edit' ? 'changeUser' : 'addUser');
            selectionCriteria.params = [formValues.email, formValues.role === 'sgts_admin'];
        }else{
            // Edit business user
            if(action === 'edit'){
                try {
                    await getModelResult(ModelSgtsJs,
                            {
                                method: 'BuUserManagementApi.changeBuName',
                                params: [formValues.email, formValues.buName]
                            });
                } catch (err){
                    setError('Failed to update business unit ' + err.error);
                }
                selectionCriteria.method = 'BuUserManagementApi.changeBuUserRole';
                selectionCriteria.params = [formValues.email, formValues.role];
            }else{
                // Create business user
                selectionCriteria.method = 'BuUserManagementApi.addBuUser';
                selectionCriteria.params = [formValues.email, formValues.buName, formValues.role];
            }
        }
        try {
            const resultSet = await getModelResult(ModelSgtsJs, selectionCriteria);
            // status is success when at this point
            console.log(resultSet.data);
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
            dispatch({type: 'action', value: null});
        } catch (resultSetError){
            // error handling
            setError('Failed create user. ' + resultSetError.error);
        }
    };

    return <ViewGridRow className="page-row">
        <NavigationBack payload={{id: null, action: null}}/>
        <h1>{props.type === 'edit' ? 'Edit user' : 'New user'}</h1>
        <ViewPanel>
            <FeedbackError error={error}/>

            <UIInputItem Component={action === 'edit' ? UIInputLabel : UIInput} name="email"
                    value={formValues.email} label="E-mail"
                    onChange={(newValue) => {setFormValues({...formValues, email: newValue});}}/>

            <UIInputItem Component={UIInputSelect} name="role" value={formValues.role} label="User type"
                    options={roles}
                    onChange={(newValue) => {setFormValues({...formValues, role: newValue});}}/>

            {formValues.role.substring(0, 4) !== 'sgts' ?
                <UIInputItem Component={UIInputSelect} name="buName" value={formValues.buName}
                        label="Business unit" options={units}
                        onChange={(newValue) => {setFormValues({...formValues, buName: newValue});}}/> :
                null
            }

            <UIButtonConfirm className="btn-submit" onClick={saveUser}>
                {action === 'edit' ? 'Edit' : 'Create'} user
            </UIButtonConfirm>
        </ViewPanel>
    </ViewGridRow>;
}

UsersEdit.propTypes = {
    type: PropTypes.oneOf(['new', 'edit', 'delete']),
    id: PropTypes.string
};

UsersEdit.defaultProps = {
    type: 'new'
};

export {UsersEdit};
