import React, {useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {ViewGridRow} from '../../../view/grid/row.js';
import {ViewGridCol} from '../../../view/grid/col.js';
import {UIInputPassword} from '../../../components/ui/input/password.js';
import {UIButtonConfirm} from '../../../components/ui/button/confirm.js';
import {UIButtonYubikey} from '../../../components/ui/button/yubikey.js';
import {FeedbackError} from '../../../components/feedback/error.js';
import {useUser} from '../../../model/use_user.js';
import {ModelSgtsJs} from '../../../model/sgts-js.js';
import {UITagCopy} from '../../../components/ui/tag/copy.js';
import './keys.scss';

function UsersKeysCreate(props){
    const [formValues, setFormValues] = useState({
        passphrase: '',
        passphrase2: ''
    });
    const [yubiKeyProtection, setYubiKeyProtection] = useState(null);
    const user = useUser();
    const [error, setError] = useState(null);
    const [personalKeyId, setPersonalKeyId] = useState(null);
    const [masterKeyId, setMasterKeyId] = useState(null);

    const createKeys = async () => {
        if(! yubiKeyProtection){
            if(!formValues.passphrase){
                setError('Please provide a secure passphrase to encrypt your private key');
                return;
            }else if(formValues.passphrase !== formValues.passphrase2){
                setError('The two passphrases provided are not the same.');
                return;

            // TODO: are these passphrase rules okay?
            }else if(formValues.passphrase.length < 8){
                setError('Passphrase should be at least 8 characters.');
                return;
            }else if(! (formValues.passphrase.match(/[a-z]/) && formValues.passphrase.match(/[A-Z]/))){
                setError('Passphrase should contain mixed case characters.');
                return;
            }else if(! formValues.passphrase.match(/[^a-zA-Z\d]/)){
                setError('Passphrase should contain special characters other than letters or numbers.');
                return;
            }
        }

        // Create keypairs
        try {
            setYubiKeyProtection(null);
            const protection = yubiKeyProtection ||
                await globalThis.SGTSUser.passphraseGenerate(formValues.passphrase);
            const result = await globalThis.SGTSUser.generateKeyPair(user.email, protection);

            if(user.isFirstUser){
                // This is the initial user, create master key
                try {
                    await globalThis.SGTSUser.generateMasterKey(user.email, protection);
                    // getting the (non-encrypted) master key to generate ID
                    const masterKey = await globalThis.SGTSUser.getMasterKey(user.email, protection);
                    setMasterKeyId(await globalThis.KeyDerivation.getMasterKeyId(masterKey));
                } catch (err){
                    console.error('Could not create master key. ' + error);
                }
            }
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
            const keyId = await globalThis.KeyDerivation.publicKeyId(result.publicKey);
            props.updateModal((prev) => {return {...prev, title: 'Keypair created'};});
            setPersonalKeyId(keyId);
        } catch (error){
            console.log(error);
            setError('Failed to setup keypair. ' + error);
        }
    };

    const createYubiKey = async () => {
        try {
            const protection = await globalThis.SGTSUser.yubikeyGenerate();
            setYubiKeyProtection(protection);
            setError(null);
        } catch (err){
            setError('Failed to generate ProtectionSecret. ' + err);
        }
    };
    useEffect(() => {
        if(yubiKeyProtection){
            createKeys();
        }
    }, [yubiKeyProtection, createKeys]);

    const closeModal = () => {
        props.updateModal({isVisible: false});
    };

    if(personalKeyId){
        const hasMasterKey = user.signedMasterKey !== null;
        return <div>
            {hasMasterKey ?
                <p>
                    You personal and master keypairs are created. You can now create wallets, execute
                    transactions and authorize other SGTS users to execute transactions using the master key.
                    Shown below are ids of your keys that can be used to verify the keys returned from the
                    system.
                    <br/><br/>
                </p> :
                <p>
                    You personal keypair is created. The SGTS admin can now grant you access to the master
                    key. The SGTS admin will need to verify your key id shown below. Please share the key id
                    with the admin and request access to the master key. Once you have access you can execute
                    transactions.
                    <br/><br/>
                </p>
            }
            <div className="center-align">
                <br/><br/>
                {/* Probably not required for the main admin user (as it is already activated). */}
                <div className="key-box-large">
                    <strong>Personal key id:</strong>
                    {hasMasterKey ? null : <span className="small-note">(Share with SGTS Admin)</span>}
                    <UITagCopy truncateLength={-1}>{personalKeyId}</UITagCopy>
                </div>

                {/* Not realy required to show this, because master key is already signed with private key.*/}
                {hasMasterKey ?
                    <div className="key-box-large">
                        <strong>Master key id:</strong>
                        <UITagCopy truncateLength={-1}>{masterKeyId}</UITagCopy>
                    </div> :
                    null
                }
            </div>

            <p className="center-align">
                <UIButtonConfirm onClick={closeModal}>
                    Got it!
                </UIButtonConfirm>
            </p>
        </div>;
    }

    return <div>
        <p>
            A keypair will be created after you create a passphrase. You can also use a YubiKey. After
            creation you will need the passphrase or YubiKey to authorise a transaction.
            <br/><br/>
        </p>
        <FeedbackError error={error}/>
        <ViewGridRow className="auth-protection-secret">
            <ViewGridCol width={67}>
                <span>Passphrase:</span><br/>
                <UIInputPassword name="passphrase" value={formValues.passphrase}
                        onChange={(passphrase) => {setFormValues({...formValues, passphrase});}}/>
                <br/><br/>
                <span>Repeat passphrase:</span><br/>
                <UIInputPassword name="passphrase2" value={formValues.passphrase2}
                        onChange={(passphrase2) => {setFormValues({...formValues, passphrase2});}}/>
            </ViewGridCol>
            <ViewGridCol width={33}>
                <span>Or YubiKey:</span><br/><br/>
                <UIButtonYubikey onChange={createYubiKey}/>
            </ViewGridCol>
        </ViewGridRow>

        <p className="center-align">
            <UIButtonConfirm onClick={createKeys}>
                Create keypair
            </UIButtonConfirm>
        </p>
    </div>;
}

UsersKeysCreate.propTypes = {
    updateModal: PropTypes.func.isRequired
};

export {UsersKeysCreate};
