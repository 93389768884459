import React from 'react';
import {PropTypes} from 'prop-types';
import {ViewGridRow} from '../../view/grid/row.js';
import {NavigationBack} from '../../components/navigation/back.js';
import {UITable} from '../../components/ui/table/table.js';
import {useUsers} from '../../model/use_users.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {ViewGridCol} from '../../view/grid/col.js';
import {NavigationBreadcrumb} from '../../components/navigation/breadcrumb.js';
import {CONFIG} from '../../config.js';
import {UIButton} from '../../components/ui/button/button.js';
import {useStateContext} from '../../helpers/state-context.js';
import {ReactComponent as IconKey} from '../../assets/icons/icon-key.svg';
import {useUsersKeysActivate} from './keys/use_users_keys_activate.js';
import {PublicKeyId} from './keys/public-key-id.js';
import './users.scss';
import {transactionsTableColumns} from '../transactions/list.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUser} from '../../model/use_user.js';
import {NavigationItemEdit} from '../../components/navigation/item-edit.js';

function UsersItem(props){
    const {users, feedback} = useUsers((user) => (user.email === props.id));
    const user = useUser();
    const [, dispatch] = useStateContext(['page', 'id', 'action']);
    const [usersKeysActivation, setUsersKeysActivate] = useUsersKeysActivate();

    const selectedUser = users.length === 0 ? null : users[0];

    const selectionCriteria = ! user ?
            {} :
            (user?.isSGTS ?
                {method: 'TransactionManagementSgtsApi.listTransactions'} :
                {method: 'TransactionManagementBuApi.listTransactions'});
    const resultSetTransaction = useModelFeedback(ModelSgtsJs, selectionCriteria);
    const transactions = resultSetTransaction.feedback ? [] : resultSetTransaction.data.filter((t) => (
        t.inputter === selectedUser?.email
    ));

    const resultSetCreditors = useModelFeedback(ModelSgtsJs,
        ! user ?
            {} :
            (user?.isSGTS ?
                {method: 'PayeeManagementApi.sgtsListBuPayees', params: [selectedUser?.unit]} :
                {method: 'PayeeManagementApi.buListPayees'}
            ));

    if(feedback || resultSetCreditors.feedback){
        return feedback || resultSetCreditors.feedback;
    }else if(! selectedUser){
        return <p className="error">User not found</p>;
    }

    // TODO: inputter not returned by API at the moment, so can't list user's creditors
    const creditors = resultSetCreditors.data.filter((item) => (
        item.inputter === selectedUser.email
    ));

    const openEventLog = () => {
        dispatch({type: 'setValues', value: {page: 'log', id: null}});
        dispatch({type: 'action', value: null});
    };

    const creditorColumns = [
        {
            id: 'address',
            title: 'Name'
        },
        {
            id: 'status',
            title: 'Status'
        }
    ];

    const isAllowEdit = ((user?.isSGTS && ! selectedUser.isSGTS) || user?.isAdmin) &&
            user?.email !== selectedUser.email;

    return <>
        <ViewGridRow className="page-row">
            <NavigationBack payload={{id: null, action: null}}/>
            <NavigationBreadcrumb path={[{title: 'Users', payload: {id: null}}]}>
                {selectedUser.email}
            </NavigationBreadcrumb>

            <ViewGridCol width={100}>
                <ViewPanel>
                    {isAllowEdit ? <NavigationItemEdit id={selectedUser.email}/> : null}
                    <dl className="user-details">
                        <dt>E-mail:</dt>
                        <dd>{selectedUser.email}</dd>
                        <dt>User type:</dt>
                        <dd>{CONFIG.userRoles[selectedUser.role] || selectedUser.role}</dd>
                        <dt>Business unit:</dt>
                        <dd>{selectedUser.unit}</dd>
                        {user?.isAdmin && selectedUser.isSGTS ?
                            <>
                                <dt>Personal key id:</dt>
                                <dd>
                                    {selectedUser.publicKey ?
                                        <span className="personal-key">
                                            <IconKey/>
                                            <PublicKeyId publicKey={selectedUser.publicKey}/>
                                        </span> :
                                        '-'
                                    }
                                </dd>
                                <dt>Master key id:</dt>
                                <dd>
                                    {selectedUser.encMasterKey ?
                                        <span className={'master-key' +
                                                (selectedUser.signedMasterKey ? ' master-key-active' : '')}>
                                            <IconKey/>{selectedUser.signedMasterKey ? 'active' : 'not-active'}
                                        </span> :
                                        '-'
                                    }
                                </dd>
                                {selectedUser.publicKey && ! selectedUser.encMasterKey && user?.hasMasterKey ?
                                    <>
                                        <dt>Active user:</dt>
                                        <dd>
                                            <UIButton onClick={() => {setUsersKeysActivate(selectedUser);}}>
                                                Activate
                                            </UIButton>
                                        </dd>
                                    </> :
                                    null
                                }
                            </> :
                            null
                        }
                    </dl>
                </ViewPanel>

            </ViewGridCol>
        </ViewGridRow>

        <ViewGridRow>
            <ViewGridCol width={33}>
                <ViewPanel className="panel-table">
                    <h2>Creditors by {selectedUser.name}</h2>
                    <UITable data={creditors} columns={creditorColumns}/>
                </ViewPanel>

                <ViewPanel className="panel-table">
                    <h2>Event log for {selectedUser.name}</h2>
                    <div>
                        <UIButton onClick={openEventLog}>Open event log</UIButton>
                    </div>
                </ViewPanel>

            </ViewGridCol>
            <ViewGridCol width={67}>
                <ViewPanel className="panel-table">
                    <h2>Transactions created by {selectedUser.name}</h2>

                    <UITable data={transactions} columns={transactionsTableColumns}/>
                </ViewPanel>
            </ViewGridCol>
        </ViewGridRow>

        {usersKeysActivation}
    </>;
}

UsersItem.propTypes = {
    id: PropTypes.string.isRequired
};

export {UsersItem};
