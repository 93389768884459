import {useState, useEffect} from 'react';
import {stringify} from '../functions/stringify.js';

/*
    Note: if the callback function binds to the current scope of the component, the data returned by this
    hook might not reflect this correctly. When the content of the function is the same, the new callback is
    not applied. If you need the trigger an update, include the dependend scope variables into the
    selectionCriteria. For example, this might not work correctly:
    const [state, dispatch] = useStateContext(['year']);
    const resultSet = useModel(ModelProject, {status: 'active'}, (m) => ( m.getData(state.year) ));
    NB: Don't add properties to the selectionCriteria that are not relevant to this model. For example simply
    passing the full state here. It will lead to many needless callbacks.
 */
const useModel = function(ModelClass, selectionCriteriaInput = {}, callback = (model) => (model.getData())){
    const selectionCriteria = typeof selectionCriteriaInput !== 'object' ? {} : selectionCriteriaInput;
    const currentCallback = ModelClass.modelName + ': ' + callback.toString();
    const [previousCallback, setPreviousCallback] = useState(currentCallback);
    const [lastFingerprint, setLastFingerprint] = useState('');
    const fingerprint = currentCallback + ';' + stringify(selectionCriteria);

    let initResultSet = {
        status: ModelClass.Status.INACTIVE,
        data: [],
        _callbackName: previousCallback,
        _lastModified: 0
    };
    if(lastFingerprint === ''){
        const {instance} = ModelClass.getExistingInstance(ModelClass, selectionCriteria);
        if(instance !== null && instance.status === ModelClass.Status.SUCCESS){
            initResultSet = {
                ...initResultSet,
                ...callback(instance)
            };
        }
    }
    const [data, setData] = useState(initResultSet);

    const [lastModified, setLastModified] = useState(data._lastModified);

    // sets up the model with callback
    useEffect(() => {
        const cb = (model) => {
            // only update if there's something new (or older, it's possible that the model switched back to
            // an older instance). Or when the data in the model has not changed, but the selectionCriteria
            // have. In this case you may want to filters your resultset in the callback.
            if(lastModified !== model.lastModified ||
                    (lastFingerprint !== fingerprint && data.status === ModelClass.Status.SUCCESS)){
                if(lastModified !== model.lastModified){
                    setLastModified(model.lastModified);
                }
                const resultSet = callback(model);
                if(resultSet === null || typeof resultSet !== 'object'){
                    console.error('Data callback from model ' + ModelClass.modelName +
                            ' did not return an object. We need an object.');
                }

                resultSet._callbackName = currentCallback;
                resultSet._lastModified = model.lastModified;

                // When the model is loading, we make available any previous data to work with in the meantime
                if(resultSet.status && previousCallback === currentCallback &&
                        (resultSet.status === ModelClass.Status.INACTIVE ||
                        resultSet.status === ModelClass.Status.WAITING)){
                    resultSet._previousResultSet = data._previousResultSet ? data._previousResultSet : data;
                }

                setData(resultSet);
                if(lastFingerprint !== fingerprint){
                    setLastFingerprint(fingerprint);
                    if(previousCallback !== currentCallback){
                        setPreviousCallback(currentCallback);
                    }
                }
            }
        };

        const {instanceId, listenerId} = ModelClass.addConsumer(ModelClass, selectionCriteria, cb);
        return () => {
            ModelClass.removeConsumer(ModelClass, instanceId, listenerId);
        };

        // Only running this effect when the fingerprint has changed or we have received new data
        // eslint-disable-next-line
    }, [fingerprint, data]);

    if(typeof selectionCriteriaInput === 'function'){
        console.error('Using a callback function as selectionCriteria is no longer supported by useModel.');
    }

    return data;
};

export {useModel};
