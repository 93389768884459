import React from 'react';
import {ViewGridRow} from '../../view/grid/row.js';
import {UITable} from '../../components/ui/table/table.js';
import {useUser} from '../../model/use_user.js';
import {UIButtonNew} from '../../components/ui/button/new.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {NavigationLink} from '../../components/navigation/link.js';
import {UITagStatus} from '../../components/ui/tag/status.js';
import {UITagTransaction} from '../../components/ui/tag/transaction.js';
import {TransactionsCreditorName} from './creditor-name.js';
import {formatDateTime} from '../../helpers/format/date_time.js';

const transactionsTableColumns = [
    {
        id: 'id',
        title: 'Id',
        width: 1,
        value: (row) => (
            <NavigationLink payload={{page: 'transactions', id: row.id}}># {row.id}</NavigationLink>
        )
    },
    {
        id: 'changed',
        title: 'Last changed',
        width: 2,
        value: (row) => (row.timestamp ? formatDateTime(row.timestamp) : '-')
    },
    {
        id: 'description',
        title: 'Description',
        width: 4,
        value: (row) => (
            <NavigationLink payload={{page: 'transactions', id: row.id}}>{row.description}</NavigationLink>
        )
    },
    {
        id: 'from',
        title: 'From',
        width: 2,
        value: (row) => (row.buName)
    },
    {
        id: 'amount',
        title: 'Amount',
        width: 3,
        value: (row) => (<UITagTransaction amount={row.amount} currency={row.tokenId} type="out"/>)
    },
    {
        id: 'to',
        title: 'To',
        width: 2,
        value: (row) => (row),
        renderCell: (row) => (<TransactionsCreditorName transaction={row} showLink={false}/>)
    },
    {
        id: 'status',
        title: 'Status',
        width: 2,
        headerStyle: {textAlign: 'right'},
        value: (row) => (<UITagStatus status={row.status}/>)
    }
];

function TransactionsList(){
    const user = useUser();

    const selectionCriteria = ! user ?
            {} :
            (user.isSGTS ?
                {method: 'TransactionManagementSgtsApi.listTransactions'} :
                {method: 'TransactionManagementBuApi.listTransactions'});
    const resultSet = useModelFeedback(ModelSgtsJs, selectionCriteria);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    return <ViewGridRow className="page-row">
        {user?.role !== 'approve' ?
            <UIButtonNew className="btn-right">New transaction</UIButtonNew> :
            null
        }
        <h1>Transactions</h1>
        <UITable data={resultSet.data} columns={transactionsTableColumns}/>

    </ViewGridRow>;
}

export {TransactionsList, transactionsTableColumns};
