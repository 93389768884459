import React from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {WalletsList} from './list.js';
import {WalletsEdit} from './edit.js';
import {WalletsItem} from './item.js';

function PageWallets(){
    const [state] = useStateContext(['page', 'id', 'action']);
    if(state.page !== 'wallets'){
        return null;
    }
    if(state.action === 'new'){
        return <WalletsEdit type="new"/>;
    }else if(state.action === 'edit' && state.id){
        return <WalletsEdit type="edit" id={state.id}/>;
    }else if(state.id){
        return <WalletsItem id={state.id}/>;
    }
    return <WalletsList/>;
}

export {PageWallets};
