import React from 'react';
import {ViewGridRow} from '../view/grid/row.js';
import {useModelFeedback} from '../helpers/model/use_model_feedback.js';
import {UITable} from '../components/ui/table/table.js';
import {NavigationLink} from '../components/navigation/link.js';
import {ModelSgtsJs} from '../model/sgts-js.js';
import {ViewPanel} from '../view/panel/panel.js';
import {ViewGridCol} from '../view/grid/col.js';
import {useUser} from '../model/use_user.js';
import {UITagStatus} from '../components/ui/tag/status.js';
import {transactionsTableColumns} from './transactions/list.js';

const PageDashboard = function(props){
    const user = useUser();
    // BU user and SGTS user have their own method for fetching payees.
    const selectionCriteria = ! user ?
            {} :
            (user.isSGTS ?
                {method: 'PayeeManagementApi.sgtsListAllPayees'} :
                {method: 'PayeeManagementApi.buListPayees'}
            );
    const resultSetCreditors = useModelFeedback(ModelSgtsJs, selectionCriteria);
    const creditors = resultSetCreditors.data ?
            [...resultSetCreditors.data].sort((a, b) => (
                a.creationDate < b.creationDate ? 1 : -1
            )) :
            [];

    const creditorsColumns = [
        {
            id: 'name',
            title: 'Name',
            width: 3,
            value: (row) => (row),
            renderCell: (row) => {
                // Payee is identified by wallet, blockchainId and bu_name...
                const id = [row.address, row.chainId, row.bu].join('||');
                return <NavigationLink payload={{page: 'creditors', id}}>
                    {row.description}
                </NavigationLink>;
            }
        },
        {
            id: 'bu',
            title: 'Unit',
            width: 2,
            value: (row) => (row.bu)
        },
        {
            id: 'status',
            title: 'Status',
            width: 2,
            value: (row) => (row.status || '-'),
            renderCell: (value) => (<UITagStatus status={value} type="creditor"/>),
            headerStyle: {textAlign: 'right'}
        }
    ];

    // Transactions with action pending from you
    const selectionCriteriaTransaction = ! user ?
            {} :
            user.isSGTS ?
                {method: 'TransactionManagementSgtsApi.listTransactions'} :
                {method: 'TransactionManagementBuApi.listTransactions'};
    const resultSetTransaction = useModelFeedback(ModelSgtsJs, selectionCriteriaTransaction);
    const transactionsPending = resultSetTransaction.feedback ? [] : resultSetTransaction.data.filter((t) => {
        if(user?.isSGTS){
            return ['approved', 'sgts_approved'].includes(t.status);
        }
        return user?.role === 'approve' ? t.status === 'created' : false;
    });

    // Transaction you created and are waiting approval
    const transactionsMine = user?.isSGTS || resultSetTransaction.feedback ?
        [] :
        resultSetTransaction.data.filter((t) => (
            // Should we show creditors that are rejected in this list or not?
            t.inputter === user?.email && ! ['executed', 'canceled', 'rejected'].includes(t.status)
        ));

    // creditors with action pending from you
    const creditorsPending = creditors.filter((c) => {
        if(user?.isSGTS){
            return ['approved'].includes(c.status);
        }
        return user?.role === 'approve' ? c.status === 'created' : false;
    });

    // creditors you created and waiting approval
    const creditorsMine = creditors.filter((c) => (
        // Should we show creditors that are rejected in this list or not?
        c.createdBy === user?.email && ! ['sgts_approved', 'rejected'].includes(c.status)
    ));

    const actionsPending = creditorsPending.length + transactionsPending.length;
    const transactionsCount = resultSetTransaction.data?.length || '0';

    return <div>
        <ViewGridRow>
            <h1>Dashboard</h1>
        </ViewGridRow>
        <ViewGridRow>
            <ViewGridCol width={33}>
                <ViewPanel className="panel-table">
                    <h2>Transactions</h2>
                    <div>
                        <p className="large-metric">
                            <strong>{transactionsCount}</strong>
                            &nbsp;Transaction{transactionsCount === 1 ? '' : 's'}
                            <br/><br/>
                        </p>
                    </div>
                </ViewPanel>
            </ViewGridCol>
            <ViewGridCol width={33}>
                <ViewPanel className="panel-table">
                    <h2>Pending actions</h2>
                    <div>
                        <p className="large-metric">
                            <strong>{actionsPending}</strong> Action{actionsPending === 1 ? '' : 's'} pending
                            <br/><br/>
                        </p>
                    </div>
                </ViewPanel>
            </ViewGridCol>
            <ViewGridCol width={33}>
                <ViewPanel className="panel-table">
                    <h2>Last creditors added</h2>
                    {resultSetCreditors.feedback ?
                        resultSetCreditors.feedback :
                        <UITable data={creditors.slice(0, 5)} columns={creditorsColumns}/>
                    }
                </ViewPanel>
            </ViewGridCol>
        </ViewGridRow>

        {transactionsPending.length > 0 ?
            <ViewGridRow className="page-row">
                <ViewGridCol width={100}>
                    <h2>Transactions waiting for (your) approval</h2>
                    <UITable data={transactionsPending} columns={transactionsTableColumns}/>
                </ViewGridCol>
            </ViewGridRow> :
            null
        }

        {transactionsMine.length > 0 ?
            <ViewGridRow className="page-row">
                <ViewGridCol width={100}>
                    <h2>Transactions created by you waiting for approval</h2>
                    <UITable data={transactionsMine} columns={transactionsTableColumns}/>
                </ViewGridCol>
            </ViewGridRow> :
            null
        }

        {creditorsPending.length > 0 ?
            <ViewGridRow className="page-row">
                <ViewGridCol width={100}>
                    <h2>Creditors waiting for (your) approval</h2>
                    <UITable data={creditorsPending} columns={creditorsColumns}/>
                </ViewGridCol>
            </ViewGridRow> :
            null
        }

        {creditorsMine.length > 0 ?
            <ViewGridRow className="page-row">
                <ViewGridCol width={100}>
                    <h2>Creditors created by you waiting for approval</h2>
                    <UITable data={creditorsMine} columns={creditorsColumns}/>
                </ViewGridCol>
            </ViewGridRow> :
            null
        }
    </div>;
};

export {PageDashboard};
