import './tag.scss';
import React from 'react';
import {PropTypes} from 'prop-types';
import './tag.scss';

const getStatusLabel = (status, type = 'transaction') => {
    // At the moment all statuses of creditors are also presend in transactions, so we simply use one list of
    // labels for both types right now.
    const labels = {
        created: 'Created',
        approved: 'Approved',
        rejected: 'Rejected',
        sgts_approved: 'Final',
        canceled: 'Canceled',
        executing: 'Executing',
        executed: 'Executed'
    };
    return labels[status] || status;
};

const UITagStatus = function(props){
    return <span className={'zol-tag-status status-' + props.status}>
        <em>{getStatusLabel(props.status, props.type)}</em>
    </span>;
};

UITagStatus.defaultProps = {
    type: 'transaction'
};

UITagStatus.propTypes = {
    status: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['transaction', 'creditor'])
};

export {UITagStatus, getStatusLabel};
