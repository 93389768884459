import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {UIButtonDownload} from '../../components/ui/button/download.js';
import {useStateContext} from '../../helpers/state-context.js';
import './wallets.scss';
import {AuthProtectionSecret} from '../../components/auth/protection-secret.js';
import {saveAsFile} from '../../helpers/functions/save_as_file.js';

function WalletsRecovery(props){
    const [, dispatch] = useStateContext(['page', 'id']);
    const [wallet, setWallet] = useState(props.wallet);

    const unlockWallet = async (protectionSecret) => {
        const wallet = await globalThis.Wallet.getWalletByBu(props.unit, protectionSecret);
        setWallet(wallet);
    };

    if(! wallet){
        return <div>
            <h2>Unlock wallet</h2>
            <p>Unlock this wallet to get access to the recovery details.</p>
            <AuthProtectionSecret setSecret={unlockWallet} submitCaption="Unlock wallet"/>
        </div>;
    }

    const closeModal = () => {
        if(props.onClose){
            props.onClose();
            return;
        }
        dispatch({type: 'id', value: props.unit});
        dispatch({type: 'action', value: null});
    };
    const downloadFile = () => {
        const blob = new Blob([wallet.doubleEncryptedBlob], {type: 'text/plain;charset=UTF-8'});
        saveAsFile(blob, 'backup-wallet-' + props.unit + '.blob');
    };

    return <div>
        <p>
            The new wallet is created. To be able to recover access to your wallet you
            need <strong>both</strong> the words and the file provided below. Download and save it in a
            secure location.
        </p>
        <p>Write down the words and keep it safe at a physical location.<br/><br/></p>

        <p className="center-align">
            Recovery file:<br/>
            <UIButtonDownload onClick={downloadFile}>
                Download recovery file
            </UIButtonDownload>
        </p>

        <p className="center-align">
            Recovery words:<br/>
            {wallet.getBackupWords().split(' ')
                    .map((word) => (
                        <span className="recovery-word" key={word}>{word}</span>
                    ))
            }
        </p>

        <p className="center-align">
            <UIButtonConfirm onClick={closeModal}>
                Got it!
            </UIButtonConfirm>
        </p>
    </div>;
}

WalletsRecovery.propTypes = {
    unit: PropTypes.string.isRequired,
    wallet: PropTypes.object,
    onClose: PropTypes.func
};

export {WalletsRecovery};
