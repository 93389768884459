import {Model} from '../helpers/model/model.js';
import {loginTriggered} from './use_user.js';
import 'sgts-js';

class ModelSgtsJs extends Model {
    static modelName = 'ModelSgtsJs';

    static getSelect(input){
        return input;
    }

    constructor(selectData, options = {}){
        super(selectData, {});
        this.cacheLimit = 25;
    }

    // Only execute a request when method is defined
    fetchData(){
        try {
            if(! this.select.method || loginTriggered){
                this.setData([]);
                return;
            }
            const namespace = this.select.method.split('.');
            const method = namespace.reduce((obj, ns) => (
                obj && obj.hasOwnProperty(ns) ? obj[ns] : null
            ), globalThis);
            if(! method){
                throw new Error('Method ' + this.select.method + ' not found.');
            }
            method.apply(this, this.select.params || [])
                    .then((data) => {
                        this.setData(data);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setError(err);
                    });
        } catch (err){
            this.setError('Failed loading data in ModelSgtsJs: ' + err);
        }
    }

    getSelect(input){
        return ModelSgtsJs.getSelect(input);
    }
}

export {ModelSgtsJs};
