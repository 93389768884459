/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
const React = require('react');
const REACT_STATICS = {
    childContextTypes: true,
    contextType: true,
    contextTypes: true,
    defaultProps: true,
    displayName: true,
    getDefaultProps: true,
    getDerivedStateFromProps: true,
    mixins: true,
    propTypes: true,
    type: true
};

const KNOWN_STATICS = {
    name: true,
    length: true,
    prototype: true,
    caller: true,
    callee: true,
    arguments: true,
    arity: true
};

const FORWARD_REF_STATICS = {
    $$typeof: true,
    render: true
};

const TYPE_STATICS = {};
TYPE_STATICS[React.ForwardRef] = FORWARD_REF_STATICS;

const defineProperty = Object.defineProperty;
const getOwnPropertyNames = Object.getOwnPropertyNames;
const getOwnPropertySymbols = Object.getOwnPropertySymbols;
const getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
const getPrototypeOf = Object.getPrototypeOf;
const objectPrototype = Object.prototype;

const hoistNonReactStatics = function(targetComponent, sourceComponent, blacklist){
    // don't hoist over string (html) components
    if(typeof sourceComponent !== 'string'){
        if(objectPrototype){
            const inheritedComponent = getPrototypeOf(sourceComponent);
            if(inheritedComponent && inheritedComponent !== objectPrototype){
                hoistNonReactStatics(targetComponent, inheritedComponent, blacklist);
            }
        }

        let keys = getOwnPropertyNames(sourceComponent);

        if(getOwnPropertySymbols){
            keys = keys.concat(getOwnPropertySymbols(sourceComponent));
        }

        const targetStatics = TYPE_STATICS[targetComponent['$$typeof']] || REACT_STATICS;
        const sourceStatics = TYPE_STATICS[sourceComponent['$$typeof']] || REACT_STATICS;

        for(let i = 0; i < keys.length; ++i){
            const key = keys[i];
            if(!KNOWN_STATICS[key] &&
                    !(blacklist && blacklist[key]) &&
                    !(sourceStatics && sourceStatics[key]) &&
                    !(targetStatics && targetStatics[key])){
                const descriptor = getOwnPropertyDescriptor(sourceComponent, key);
                try { // Avoid failures from read-only properties
                    defineProperty(targetComponent, key, descriptor);
                } catch (e){}
            }
        }

        return targetComponent;
    }

    return targetComponent;
};

export {hoistNonReactStatics};
