import React from 'react';
import {PropTypes} from 'prop-types';
import cssVariables from './grid.module.scss';
import './grid.scss';

const ViewGridCol = function(props){
    const width = 'calc(' + props.width + '% - ' + cssVariables.columnSpacing + 'px';
    const className = 'zol-grid-col' + (props.className ? ' ' + props.className : '');
    return <div className={className} style={{width}}>
        {props.children}
    </div>;
};

ViewGridCol.defaultProps = {
    width: 100,
    className: ''
};

ViewGridCol.propTypes = {
    width: PropTypes.number,
    className: PropTypes.string
};

export {ViewGridCol};
