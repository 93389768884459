import React from 'react';
import {PropTypes} from 'prop-types';
import {NavigationLink} from '../navigation/link.js';
import {formatDateTime} from '../../helpers/format/date_time.js';
import './workflow.scss';

const steps = {
    created: 'Created',
    approved: 'Approved',
    sgts_approved: 'Finally approved',
    rejected: 'Rejected'
};

const pendingAction = {
    approved: 'BU Approver',
    sgts_approved: 'SGTS Admin'
};

const timestampKeys = {
    created: 'creationDate',
    approved: 'buApprovalDate',
    sgts_approved: 'sgtsApprovalDate',
    rejected: 'rejectionDate'
};
const approverKeys = {
    created: 'createdBy',
    approved: 'buApprovedBy',
    sgts_approved: 'sgtsApprovedBy',
    rejected: 'rejectedBy'
};

const WorkflowCreditor = function(props){
    const stepsInUse = {...steps};
    // We either show rejected or the approval steps
    if(props.item.status === 'rejected'){
        // keep all steps that were already approved and delete the rest
        const statusses = Object.keys(steps).slice(1).reverse();
        for(const s of statusses){
            if(props.item[timestampKeys[s]] === null){
                delete stepsInUse[s];
            }
        }
    }else{
        delete stepsInUse.rejected;
    }
    const currentLevel = Object.keys(stepsInUse).indexOf(props.item.status);

    return <div className="workflow workflow-creditor">
        {Object.keys(stepsInUse).map((status, index) => {
            const isActive = index <= currentLevel;
            const isActiveBefore = index - 1 <= currentLevel;
            const isActiveAfter = index + 1 <= currentLevel;
            const activeClass = (isActive ? ' is-active' : '') + (isActiveBefore ? ' is-active-before' : '') +
                    (isActiveAfter ? ' is-active-after' : '');
            const timestamp = isActive ? props.item[timestampKeys[status]] : null;
            const approver = isActive ? props.item[approverKeys[status]] : null;
            return <div key={status} className={'workflow-step' + (index + 1) + activeClass}>
                <h4>{stepsInUse[status]}</h4>
                {isActive ?
                    <NavigationLink payload={{page: 'users', id: approver}}>
                        {approver}
                    </NavigationLink> :
                    <h3>{pendingAction[status]}</h3>
                }
                {timestamp ? <span>{formatDateTime(timestamp)}</span> : <span/>}
            </div>;
        })}
    </div>;
};

WorkflowCreditor.propTypes = {
    item: PropTypes.shape({
        status: PropTypes.oneOf(Object.keys(steps)).isRequired,
        bu_approval_date: PropTypes.string,
        bu_approved_by: PropTypes.string,
        created_by: PropTypes.string,
        creation_date: PropTypes.string,
        rejected_by: PropTypes.string,
        rejection_date: PropTypes.string,
        sgts_approval_date: PropTypes.string,
        sgts_approved_by: PropTypes.string
    }).isRequired
};

export {WorkflowCreditor};
