import React from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {TransactionsList} from './list.js';
import {TransactionsCreate} from './create.js';
import {TransactionsItem} from './item.js';

function PageTransactions(){
    const [state] = useStateContext(['page', 'id', 'action']);
    if(state.page !== 'transactions'){
        return null;
    }

    if(state.action === 'new'){
        return <TransactionsCreate/>;
    }else if(state.id){
        return <TransactionsItem id={state.id}/>;
    }
    return <TransactionsList/>;
}

export {PageTransactions};
