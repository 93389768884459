
const getUniqueId = function(length){
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for(let i = length; i > 0; i--){
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
};

export {getUniqueId};
