import './button.scss';
import React from 'react';
import {PropTypes} from 'prop-types';
import {UIButton} from './button.js';

const UIButtonIcon = function(props){
    return <UIButton className={(props.className + ' zol-ui-button-icon').trim()} title={props.title}
            onClick={props.onClick}>
        <props.Icon/>
        {props.children}
    </UIButton>;
};

UIButtonIcon.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string
};

UIButtonIcon.defaultProps = {
    className: 'zol-primary',
    onClick: () => {},
    title: null
};

export {UIButtonIcon};
