import React from 'react';
import PropTypes from 'prop-types';
import {UITableSort} from './sort.js';
import {UITableFilter} from './filter.js';

const UITableHeader = function(props){
    const {columns, order, setOrder, offsetTop, scrollOffset} = props;

    const totalWidth = columns.reduce((sum, col) => (sum + col.width), 0);
    const styleHeader = {
        // position fixed gives better performance, but we can't get it to work with scrolling enabled
        position: offsetTop === 0 ? 'static' : (props.isScrolling ? 'absolute' : 'fixed'),
        top: offsetTop < 0 ? offsetTop + 'px' : (props.isScrolling ? offsetTop + 'px' : '0px'),
        zIndex: offsetTop !== 0 ? '1' : '0',
        width: totalWidth,
        overflowX: offsetTop === 0 ? (props.isScrolling ? 'auto' : 'inherrit') :
                (props.isScrolling ? 'hidden' : 'visible'),
        overflowY: props.isScrolling ? 'hidden' : 'visible',
        height: offsetTop === 0 ? 'auto' : props.contentHeight
    };

    const getCellStyle = (column) => {
        return {
            position: 'relative',
            left: column.isFixed ? scrollOffset : '',
            width: column.width,
            wordBreak: 'break-word',
            zIndex: column.isFixed ? 1 : 0,
            ...(column.headerStyle || {})
        };
    };

    const isParentHeaderActive = columns.some((column) => (column.parentTitle));
    let skipColumns = 0;
    const headerRow1 = isParentHeaderActive ?
        <tr className="zol-table-parent-header">
            {columns.reduce((result, column, colIndex) => {
                if(skipColumns > 0){
                    skipColumns--;
                    return result;
                }
                let colSpan = 1;
                for(let i = colIndex + 1; i < columns.length; i++){
                    if(columns[i].parentTitle !== column.parentTitle){
                        break;
                    }
                    colSpan++;
                }
                skipColumns = colSpan - 1;
                result.push(<th key={colIndex}
                        className="zol-table-header-item"
                        style={getCellStyle(column)}
                        colSpan={colSpan}>
                    <span className="zol-table-header-item-label">
                        {column.renderParentTitle ?
                            column.renderParentTitle(column.parentTitle) :
                            column.parentTitle}
                    </span>
                </th>);
                return result;
            }, [])}
        </tr> :
        null;

    const headerRow2 = <tr className="zol-table-header-child">
        {columns.map((column, colIndex) => {
            const className = 'zol-table-header-item' +
                    (column.rotateHeader ? ' zol-table-header-item-rotated' : '');
            return <th key={colIndex}
                    className={className}
                    style={getCellStyle(column)}>

                <span className="zol-table-header-item-label">
                    {column.renderTitle ? column.renderTitle(column.title) : column.title}
                </span>

                {column.sort ?
                    <UITableSort sort={column.sort}
                            id={column.id}
                            order={order}
                            allowUnset={props.allowNoOrder}
                            onSelect={(value) => setOrder(value)}/> :
                    null
                }
                {column.filter ?
                    <UITableFilter onChange={(value) => (props.setFilter(column.id, value))}/> :
                    null
                }
            </th>;
        })}

    </tr>;

    return <thead className="zol-table-header" style={styleHeader}>
        {headerRow1}
        {headerRow2}
    </thead>;
};

UITableHeader.defaultProps = {
    setFilter: (column, filterValue) => {},
    setOrder: () => {},
    allowNoOrder: false,
    isScrolling: false,
    offsetTop: 0,
    contentHeight: 50,
    scrollOffset: 0
};

UITableHeader.propTypes = {
    order: PropTypes.string,
    setOrder: PropTypes.func,
    allowNoOrder: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    setFilter: PropTypes.func,
    offsetTop: PropTypes.number,
    isScrolling: PropTypes.bool,
    contentHeight: PropTypes.number,
    scrollOffset: PropTypes.number
};

export {UITableHeader};
