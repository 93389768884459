import {NetworkXhr} from '../network/xhr.js';
import {Model} from './model.js';
import {getHash} from '../functions/get_hash.js';
import {stringify} from '../functions/stringify.js';

class ModelXhr extends Model {
    static saveData(dependentModelClasses, endpoint, method, payload, callback = () => {}, options){
        options = {
            method,
            data: payload,
            authorization: ModelXhr.getAuthorization(),
            useXMLHttpRequest: true,
            contentType: 'json',
            ...options
        };

        const hash = getHash(method + ' ' + endpoint + ' ' + stringify(options));
        if(Model.saveRequestQue.has(hash)){
            // same request is already being executed, ignore this one. It's probably triggered twice.
            return;
        }

        // TODO we want to push messages to a global message system, so we can show feedback to the user.
        const onFailure = (error) => {
            Model.saveRequestQue.delete(hash);
            console.error('Error saving data: ' + error);
            // GlobalMessage.push({type: 'error', message: 'Could not save item'});
            callback({success: false, error});
        };

        const onSuccess = (rawResponse) => {
            try {
                Model.saveRequestQue.delete(hash);

                let response = null;
                try {
                    if(options.parseResponse){
                        response = options.parseResponse(rawResponse);
                    }else{
                        response = JSON.parse(rawResponse);
                    }
                } catch (e){
                    response = {
                        success: false,
                        error: 'Error parsing response: ' + e.getMessage()
                    };
                }

                if(! response['success']){
                    onFailure(response['error'] || 'The server returned an unknown error');
                    return null;
                }
                Model.invalidateAllInstances(dependentModelClasses);
                callback(response);
                // GlobalMessage.push({type: 'info', message: 'Item saved!'});
            } catch (e){
                onFailure(e.message);
            }
        };

        const request = new NetworkXhr(endpoint, options, onSuccess, onFailure);
        request.start();

        // GlobalMessage.push({type: 'loading'});

        Model.saveRequestQue.set(hash, {
            modelClasses: dependentModelClasses,
            onSuccess,
            onFailure,
            options
        });
    }

    static getAuthorization(){
        return sessionStorage.getItem('auth_token') === null ?
                null :
                {
                    type: 'Bearer',
                    access_token: sessionStorage.getItem('auth_token')
                };
    }

    constructor(selectData, settings = {}){
        super(selectData);

        if(! settings.endpoint){
            console.warn('Endpoint must be set for ModelXhr.');
        }

        this.endpoint = settings.endpoint;
        this.method = settings.method || 'GET';

        this.request = null;
        this.timerId = null;
        this.useXMLHttpRequest = true;
        this.withCredentials = false;

        this.execRequest = this.execRequest.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);
    }

    getAuthorization(){
        return ModelXhr.getAuthorization();
    }

    fetchData(){
        // hold for a moment, in case more settings are going to
        // change that will again trigger a request
        clearTimeout(this.timerId);
        if(this.request != null){
            this.request.abort();
            this.status = Model.Status.WAITING;
        }
        this.timerId = setTimeout(this.execRequest, 50);

        this.trigger();
    }

    execRequest(optParams = null){
        const data = this.getPayload(this.select);

        const params = {
            method: this.method,
            useXMLHttpRequest: this.useXMLHttpRequest,
            withCredentials: this.withCredentials,
            data,
            body: this.getRequestBody(),
            contentType: this.getContentType(),
            authorization: this.getAuthorization(),
            ...(optParams === null ? {} : optParams)
        };

        this.request = new NetworkXhr(this.getEndpoint(this.select), params, this.onSuccess, this.onFailure);
        this.request.start();
    }

    getRequestBody(){
        return '';
    }

    getContentType(){
        return null;
    }

    getEndpoint(select){
        return this.endpoint;
    }

    onSuccess(dataRaw){
        this.status = Model.Status.SUCCESS;
        const meta = this.getMetaFromResponse(dataRaw);
        const data = this.getDataFromResponse(dataRaw);
        this.request = null;

        // note status might have changed in getMetaFromResponse of getDataFromResponse
        if(this.status === Model.Status.SUCCESS){
            this.setData(data, meta);
        }
    }

    onFailure(error){
        this.request = null;
        this.setError(error);
    }

    getPayload(select){
        const input = {...select};

        if(! input || typeof input != 'object'){
            return input;
        }

        for(const key of Object.keys(input)){
            if(typeof input[key] == 'object'){
                input[key] = JSON.stringify(input[key]);
            }
        }

        return input;
    }

    getMetaFromResponse(data){
        return null;
    }

    getDataFromResponse(data){
        try {
            const response = JSON.parse(data);

            if(! response['success'] && ! response['status']){ // status is used in older projects
                let error = response['error'] || response['result'] || 'The server returned an unknown error';
                if(error instanceof Array){
                    error = error.join(' \n');
                }
                console.error(this.name + ': ' + error);
                this.onFailure(error);
                return null;
            }
            return response.result === undefined ? response.value || [] : response.result;
        } catch (e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' +
                    e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }

    getCache(){
        if(this.method === 'POST'){
            return false;
        }
        return super.getCache();
    }

    remove(){
        try {
            clearTimeout(this.timerId);
            if(this.request != null){
                this.request.remove();
            }

            super.remove();
        } catch (e){
            console.warn('Error removing ModelXhr: ' + e.message);
        }
    }
}

export {ModelXhr};
