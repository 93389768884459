import React from 'react';
import {ViewGridRow} from '../../view/grid/row.js';
import {UIButtonNew} from '../../components/ui/button/new.js';
import {UITable} from '../../components/ui/table/table.js';
import {NavigationLink} from '../../components/navigation/link.js';
import {CONFIG} from '../../config.js';
import {useUsers} from '../../model/use_users.js';
import {useUser} from '../../model/use_user.js';
import {ReactComponent as IconKey} from '../../assets/icons/icon-key.svg';
import {UIButton} from '../../components/ui/button/button.js';
import {useUsersKeysActivate} from './keys/use_users_keys_activate.js';
import './users.scss';
import {PublicKeyId} from './keys/public-key-id.js';

function UsersList(){
    const {feedback, users} = useUsers();
    const user = useUser();
    const [usersKeysActivation, setUsersKeysActivate, setShowKeyUser] = useUsersKeysActivate();

    if(feedback){
        return feedback;
    }

    const columns = [
        {
            id: 'name',
            title: 'Name',
            width: 2,
            value: (row) => (row),
            renderCell: (row) => (
                <NavigationLink payload={{id: row.email}}>{row.name}</NavigationLink>
            )
        },
        {
            id: 'role',
            title: 'Role',
            width: 1,
            value: (row) => (CONFIG.userRoles[row.role] || row.role),
            renderCell: (value) => (<span className="bolder">{value}</span>)
        },
        {
            id: 'unit',
            title: 'Business unit',
            width: 2,
            value: (row) => (row.unit || '-')
        }
    ];
    if(user?.isSGTS){
        columns.push(
                {
                    id: 'personal_key',
                    title: 'Personal key',
                    width: 1,
                    value: (row) => (! row.publicKey ? '-' : row.publicKey),
                    renderCell: (value) => (value === '-' ? value :
                        <span className="personal-key">
                            <IconKey/><PublicKeyId publicKey={value}/>
                        </span>)
                },
                {
                    id: 'master_key',
                    title: 'Master key',
                    width: 1,
                    value: (row) => (row),
                    renderCell: (row) => {
                        if(! row.encMasterKey){
                            return '-';
                        }
                        const classActive = (row.signedMasterKey ? ' master-key-active' : '');
                        return <span className={'master-key' + classActive}>
                            <IconKey/> {row.signedMasterKey ? 'active' : 'not-active'}
                        </span>;
                    }
                },
                {
                    id: 'action',
                    title: '',
                    width: 1,
                    value: (row) => {
                        return {...row, needsActivation: row.publicKey && ! row.encMasterKey};
                    },
                    renderCell: (row) => {
                        if(user?.isAdmin && row.needsActivation && user?.hasMasterKey){
                            return <UIButton onClick={() => {setUsersKeysActivate(row);}}>Activate</UIButton>;
                        }else if(user?.isAdmin && row.encMasterKey && user?.hasMasterKey){
                            return <UIButton onClick={() => {setShowKeyUser(row);}}>Show key id</UIButton>;
                        }
                        return null;
                    }
                });
    };

    return <ViewGridRow className="page-row">
        {user?.isSGTS ? <UIButtonNew className="btn-right">New user</UIButtonNew> : null}
        <h1>Users</h1>

        <UITable data={users} columns={columns}/>

        {usersKeysActivation}

    </ViewGridRow>;
}

export {UsersList};
