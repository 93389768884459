import './modal.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as IconClose} from './close-outline.svg';

const UIModal = function(props){
    if(! props.visible){
        return null;
    }

    const modalStyle = {
        width: isNaN(props.width) ? props.width : Math.min(0.9 * window.innerWidth, props.width || 500),
        height: isNaN(props.height) ? props.height : Math.min(0.9 * window.innerHeight, props.height),
        ...props.style
    };

    return <div className={'zol-modal-overlay'} onClick={props.onClose}>
        <div className={'zol-modal ' + props.className} onClick={(e) => {e.stopPropagation();}}
                style={modalStyle}>
            {(props.hideHeader) ?
                null :
                <div className={'zol-modal-header'} style={props.headerStyle}>
                    <h2>{props.title}</h2>
                    <IconClose className={'close-icon'}
                            style={props.iconCloseStyle}
                            onClick={props.onClose}/>
                </div>
            }
            <div className={'zol-modal-content'} style={props.contentStyle}>
                {props.children}
            </div>
        </div>
    </div>;
};

UIModal.defaultProps = {
    title: 'Modal',
    visible: true,
    headerStyle: {},
    iconCloseStyle: {},
    contentStyle: {},
    className: ''
};

UIModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    style: PropTypes.object,
    headerStyle: PropTypes.object,
    iconCloseStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    visible: PropTypes.bool,
    hideHeader: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};

export {UIModal};
