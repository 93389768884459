import {useModel} from '../helpers/model/use_model.js';
import {ModelUser} from '../model/user.js';
import {CONFIG} from '../config.js';
import {useUsers} from './use_users.js';
import {useModelFeedback} from '../helpers/model/use_model_feedback.js';
import {ModelSgtsJs} from '../model/sgts-js.js';

let loginTriggered = false;
const useUser = () => {
    // This does not return all details, but just the email of the active user
    const resultSet = useModel(ModelUser, {method: 'user'});
    const data = resultSet.status === ModelUser.Status.SUCCESS ? {...resultSet.data} : null;
    const resultSetMasterKeySet = useModelFeedback(ModelSgtsJs,
            {method: 'SGTSUserManagementApi.masterKeyExists'});
    const masterKeyStatusReceived = resultSetMasterKeySet.status === ModelSgtsJs.Status.SUCCESS;
    const masterKeySet = masterKeyStatusReceived && resultSetMasterKeySet.data;

    let userEmail = null;
    if(data?.user){
        userEmail = data.user;
    }else if(resultSet.status === ModelUser.Status.SUCCESS){
        // we have a result, but no user. This means we are not logged in. Redirect to the login page
        if(! loginTriggered){
            console.log('user not set, going to login');
            // Give potential request that are already triggered some more time to finish, making sure they
            // will not return _after_ the login page is opened resetting the session
            setTimeout(() => {
                window.location.href = CONFIG.login_url;
            }, 500);
            // This hook may run multiple times and we do not need to trigger the login again
            loginTriggered = true;
        }
    }

    // This get the full list of users (both sgts and bu_users) and filters out the active one. Unfortunately
    // we cannot get user details by id for bu users.
    const {users, feedback} = useUsers();
    const matchedUsers = users.filter((u) => (u.email === userEmail));
    if(feedback || matchedUsers.length === 0){
        return null;
    }

    const user = {
        ...matchedUsers[0],
        isFirstUser: matchedUsers[0].isAdmin && masterKeyStatusReceived && ! masterKeySet
    };
    user.hasMasterKey = user.signedMasterKey !== null;
    return user;
};

export {useUser, loginTriggered};
