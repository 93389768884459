import React from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {NavigationLink} from './link.js';
import {useUser} from '../../model/use_user.js';
import {sitemap} from '../../sitemap.js';
import './menu.scss';

const NavigationMenu = function(props){
    const [state] = useStateContext(['page']);
    const user = useUser();

    const items = sitemap.filter((item) => (item.hasAccess(user)))
            .map((item) => {
                return {
                    ...item,
                    className: item.id === state.page ? 'active' : null
                };
            });

    return <ul className="navigation-menu">
        {items.map((item) => (
            <li key={item.id}>
                <NavigationLink payload={{page: item.id, id: null, action: null}}
                        className={item.className}>
                    {item.title}
                </NavigationLink>
            </li>
        ))}
    </ul>;
};

export {NavigationMenu};
