import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconChevronRight} from '../../assets/icons/chevron-right-outline.svg';
import {NavigationLink} from './link.js';
import './breadcrumb.scss';

const NavigationBreadcrumb = function(props){
    return <h1 className="navigation-breadcrumb">
        {props.path.map((item, index) => (<React.Fragment key={index}>
            <NavigationLink payload={item.payload}>
                {item.title}
            </NavigationLink>
            <IconChevronRight/>
        </React.Fragment>))}
        {props.children}
    </h1>;
};

NavigationBreadcrumb.propTypes = {
    path: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        payload: PropTypes.object.isRequired
    }))
};

export {NavigationBreadcrumb};
