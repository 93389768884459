import PropTypes from 'prop-types';
import React from 'react';

const UIInputLabel = function(props){
    return <span className={`zol-single-input ${props.hasError ? 'zol-single-error' : ''}`}>
        {props.value || props.placeholder || ''}
    </span>;
};

UIInputLabel.defaultProps = {
    value: '',
    hasError: false
};

UIInputLabel.propTypes = {
    hasError: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string
};

export {UIInputLabel};
