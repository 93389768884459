import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {NavigationBack} from '../../components/navigation/back.js';
import {ViewGridRow} from '../../view/grid/row.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {UIInputItem} from '../../components/ui/input/item.js';
import {UIInput} from '../../components/ui/input/input.js';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {UIModal} from '../../components/ui/modal/modal.js';
import {WalletsCreate} from './create.js';
import {FeedbackError} from '../../components/feedback/error.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';

function WalletsEdit(props){
    const [formValues, setFormValues] = useState({
        name: '',
        branch: '',
        bpNumber: ''
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('Create new wallet');
    const [error, setError] = useState(null);
    const resultSet = useModelFeedback(ModelSgtsJs, {method: 'BuManagementApi.listBusinessUnits'});
    const existingUnits = resultSet.feedback ? [] : resultSet.data;

    const saveUnit = async () => {
        if(! formValues.name){
            setError('Please provide a name for the business unit');
            return;
        }else{
            const isNameTaken = existingUnits.some((u) => (
                u.name.toLowerCase() === formValues.name.toLowerCase()
            ));
            if(isNameTaken){
                setError('This unit name is already in use. Please choose a different name.');
                return;
            }
        }
        setIsModalOpen(true);
    };

    return <ViewGridRow className="page-row">
        <NavigationBack payload={{id: null, action: null}}/>
        <h1>{props.type === 'edit' ? 'Edit wallet/unit' : 'New wallet/unit'}</h1>
        <ViewPanel>
            <FeedbackError error={error}/>

            <UIInputItem Component={UIInput} name="name" value={formValues.name} label="Name"
                    onChange={(newValue) => {setFormValues({...formValues, name: newValue});}}/>

            <UIInputItem Component={UIInput} name="branch" value={formValues.branch} label="Branch"
                    onChange={(newValue) => {setFormValues({...formValues, branch: newValue});}}/>

            <UIInputItem Component={UIInput} name="bpNumber" value={formValues.bpNumber}
                    label="Business Partner number (SAP)"
                    onChange={(newValue) => {
                        setFormValues({...formValues, bpNumber: parseInt(newValue)});
                    }}/>

            <UIButtonConfirm className="btn-submit" onClick={saveUnit}>
                Create unit
            </UIButtonConfirm>
        </ViewPanel>

        <UIModal visible={isModalOpen} title={modalTitle} width={800} height={500}
                onClose={() => {setIsModalOpen(false);}}>
            <WalletsCreate unit={formValues} setModalTitle={setModalTitle}/>
        </UIModal>

    </ViewGridRow>;
}

WalletsEdit.propTypes = {
    type: PropTypes.oneOf(['new', 'edit']),
    id: PropTypes.number
};

WalletsEdit.defaultProps = {
    type: 'new'
};

export {WalletsEdit};
