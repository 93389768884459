import React, {useState, useEffect} from 'react';
import {NavigationBack} from '../../components/navigation/back.js';
import {ViewGridRow} from '../../view/grid/row.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {FeedbackError} from '../../components/feedback/error.js';
import {UIInputItem} from '../../components/ui/input/item.js';
import {UIInput} from '../../components/ui/input/input.js';
import {UIInputSelect} from '../../components/ui/input/select.js';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {useStateContext} from '../../helpers/state-context.js';
import {CONFIG} from '../../config.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUser} from '../../model/use_user.js';
// import {useWalletBalance} from '../../model/use_wallet_balance.js';

function TransactionsCreate(props){
    const user = useUser();
    const [creditor, setCreditor] = useState(null);
    const [unit, setUnit] = useState(null);
    const [, dispatch] = useStateContext(['action']);
    const [formValues, setFormValues] = useState({
        addressTo: '',
        chainId: '',
        tokenId: '',
        amount: '',
        description: '',
        buName: ''
    });

    // getting the balance of the wallets belonging to the unit of this creditor
    // TODO: Cannot get unit or wallet details as a BU user, so we cannot fetch the balance at the moment
    // const [, , buName] = creditor ? creditor.split('||') : [''];
    // const resultSetWallet = useModelFeedback(ModelSgtsJs,
    //         {method: 'WalletApi.getWalletByBu', params: [buName]});
    // const walletAddress = resultSetWallet.feedback ? null : resultSetWallet.data.walletAddress;
    // const balance = useWalletBalance(unit.address, formValues.chainId, true);

    const [error, setError] = useState(null);
    const setCreditorValue = (creditor) => {
        if(creditor){
            const [address, chainId, buName] = creditor.split('||');
            const tokenIds = CONFIG.tokenIdsForChain[chainId];
            const tokenId = ! tokenIds || tokenIds.indexOf(formValues.tokenId) ?
                    formValues.tokenId : tokenIds[0];
            setFormValues((prev) => {return {...prev, addressTo: address, chainId, tokenId, buName};});
        }else{
            setFormValues((prev) => {return {...prev, addressTo: '', chainId: '', tokenId: ''};});
        }
        setCreditor(creditor);
    };

    const selectionCriteriaUnits = user?.isSGTS ? {method: 'BuManagementApi.listBusinessUnits'} : {};
    const resultSetUnits = useModelFeedback(ModelSgtsJs, selectionCriteriaUnits);
    const units = user?.isSGTS && resultSetUnits.data ? resultSetUnits.data.map((bu) => (bu.name)) : [];
    useEffect(() => {
        if(user?.isSGTS && units.length > 0 && ! units.includes(unit)){
            setUnit(units[0]);
        }
    }, [units, unit, user]);

    const selectionCriteria = ! user ||
            {method: 'PayeeManagementApi.' + (user?.isSGTS ? 'sgtsListAllPayees' : 'buListPayees')};
    const resultSet = useModelFeedback(ModelSgtsJs, selectionCriteria);
    const creditors = ! user || ! resultSet.data ?
        {} :
        resultSet.data.reduce((obj, creditor) => {
            // Only include approved creditors and creditors that are in the selected BU
            if(creditor.status === 'sgts_approved' && (! user?.isSGTS || unit === creditor.bu)){
                const id = [creditor.address, creditor.chainId, creditor.bu].join('||');
                obj[id] = creditor.description;
            }
            return obj;
        }, {});

    useEffect(() => {
        if(! formValues.addressTo && Object.keys(creditors).length > 0){
            setCreditorValue(Object.keys(creditors)[0]);
        }
        // If a creditor was set, but the Business Unit is changes and the creditor is no longer present, we
        // want to reset the previous selection of creditor.
        if(creditor && ! creditors.hasOwnProperty(creditor)){
            setCreditorValue(Object.keys(creditors).length > 0 ? Object.keys(creditors)[0] : null);
        }
    }, [creditors, formValues, creditor]);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const tokenIds = formValues.chainId ? CONFIG.tokenIdsForChain[formValues.chainId] : [];

    const createTransaction = async () => {
        if(! formValues.addressTo){
            setError('Please select a creditor from the list');
            return;
        }else if(! formValues.description){
            setError('Please provide a short description for the transaction');
            return;
        }else if(! formValues.amount){
            setError('Please select the amount for this transaction');
            return;
        }else if(isNaN(formValues.amount)){
            setError('Amount should be a numeric value');
            return;
        }
        try {
            const amount = parseFloat(formValues.amount);
            if(user?.isSGTS){
                await globalThis.TransactionManagementSgtsApi.inputTransaction(
                        formValues.addressTo,
                        parseInt(formValues.chainId, 10),
                        formValues.tokenId,
                        formValues.description,
                        '' + amount,
                        formValues.buName);
            }else{
                await globalThis.TransactionManagementBuApi.inputTransaction(
                        formValues.addressTo,
                        parseInt(formValues.chainId, 10),
                        formValues.tokenId,
                        formValues.description,
                        '' + amount);
            }
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
            dispatch({type: 'action', value: null});
        } catch (err){
            // error handling
            setError('Failed create transaction. ' + err);
        }
    };

    return <ViewGridRow className="page-row">
        <NavigationBack payload={{id: null, action: null}}/>
        <h1>New transaction</h1>
        <ViewPanel>
            <FeedbackError error={error}/>

            {user?.isSGTS ?
                <UIInputItem Component={UIInputSelect} name="unit" value={unit || ''}
                        label="Business Unit" options={units}
                        onChange={(newValue) => {setUnit(newValue);}}/> :
                null
            }

            <UIInputItem Component={UIInputSelect} name="creditor" value={creditor || ''}
                    label="Creditor" options={creditors}
                    onChange={(newValue) => {setCreditorValue(newValue);}}/>

            <UIInputItem Component={UIInput} name="description" value={formValues.description}
                    label="Description"
                    onChange={(newValue) => {setFormValues({...formValues, description: newValue});}}/>

            <UIInputItem Component={UIInputSelect} name="tokenId" value={formValues.tokenId}
                    label="Token" options={tokenIds}
                    onChange={(newValue) => {setFormValues({...formValues, tokenId: newValue});}}/>

            {/* <p>Current balance: {balance}</p> */}

            <UIInputItem Component={UIInput} name="amount" value={formValues.amount} label="Amount"
                    onChange={(newValue) => {setFormValues({...formValues, amount: newValue});}}/>

            <UIButtonConfirm className="btn-submit" onClick={createTransaction}>
                Create transaction
            </UIButtonConfirm>
        </ViewPanel>
    </ViewGridRow>;
}

export {TransactionsCreate};
