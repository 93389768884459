/*
 * Function to load data from a model outside of a component. When using it in a component it's always better
 * to use useModel or ModelConsumer as the data persists between render cycles with those two methods. Here
 * each time the function is called new data will be loaded. You can use caching in you model to reduce the
 * amount of dupplicate requests, but this function is general good when calling it just once (so not inside
 * a render method of a component). It is good when using it in a callback for example:
 * const onClick = async function(){
 *       try{
 *           const resultSet = await getModelResult(ModelStaffValueDistribution, {field: 'gender'});
 *           // status is success when at this point
 *           console.log(resultSet.data);
 *       }catch(resultSetError){
 *           // error handling
 *           alert('Failed to load data. ' + resultSetError.error);
 *       }
 *   };
 */
export const getModelResult = async function(ModelClass, selectionCriteria,
        callback = (model) => (model.getData()), control = {}){
    // Setting up the control reference object that allows the initiater to cancel the request and clean up
    control.cancel = () => {};
    const removeModel = (modelRef) => {
        ModelClass.removeConsumer(ModelClass, modelRef.instanceId, modelRef.listenerId);
        control.cancel = () => {};
    };

    return new Promise((resolve, reject) => {
        const modelRef = {instanceId: null, listenerId: null};
        let timeoutId = null;
        control.cancel = () => {
            clearTimeout(timeoutId);
            removeModel(modelRef);
        };
        const cb = (model) => {
            const resultSet = callback(model);
            if([ModelClass.Status.FAILED, ModelClass.Status.SUCCESS].includes(resultSet.status)){
                // make sure the promise is not resolved/rejected before it is returned to the caller
                timeoutId = setTimeout(() => {
                    control.cancel();
                    if(resultSet.status === ModelClass.Status.FAILED){
                        reject(resultSet);
                    }else{
                        resolve(resultSet);
                    }
                }, 0);
            }
        };
        const ref = ModelClass.addConsumer(ModelClass, selectionCriteria, cb);
        modelRef.instanceId = ref.instanceId;
        modelRef.listenerId = ref.listenerId;
    });
};
