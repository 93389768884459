import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconCheckmark} from '../../../assets/icons/checkmark-circle-outline.svg';
import {UIButtonIcon} from './icon.js';

function UIButtonConfirm(props){
    return <UIButtonIcon {...props} Icon={IconCheckmark}>
        {props.children}
    </UIButtonIcon>;
}

UIButtonConfirm.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
};

export {UIButtonConfirm};
