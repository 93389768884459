import React, {useState} from 'react';
import {useUser} from '../../model/use_user.js';
import {ReactComponent as IconPerson} from '../../assets/icons/person-outline.svg';
import {ReactComponent as IconChevronDown} from '../../assets/icons/chevron-down-outline.svg';
import {ReactComponent as IconLogout} from '../../assets/icons/log-out-outline.svg';
import './user-menu.scss';
import colors from '../../assets/variables.module.scss';
import {CONFIG} from '../../config.js';
import {useClickOutsideElement} from '../../helpers/elements/use_click_outside_element.js';

const NavigationUserMenu = function(props){
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const measuredRef = useClickOutsideElement(() => {setIsMenuOpen(false);}, document.body, isMenuOpen);

    const user = useUser();
    if(! user){
        return null;
    }

    const toggleMenu = () => {
        setIsMenuOpen(! isMenuOpen);
    };

    const pallete = [colors.colorOrange, colors.colorYellow, colors.colorBlue, colors.colorGreen];
    const userColors = Object.keys(CONFIG.userRoles).reduce((c, role, i) => {
        c[role] = pallete[i] || colors.colorLight;
        return c;
    }, {});

    return <div className="navigation-user-menu" onClick={toggleMenu}>
        <span className="user-menu-photo" style={{backgroundColor: userColors[user.role]}}>
            <IconPerson/>
        </span>
        <span className="user-menu-name">
            {user.name}
            <em>{user.function}</em>
        </span>
        <span className={'user-menu-toggle' + (isMenuOpen ? ' active' : '')}>
            <IconChevronDown/>
        </span>
        {isMenuOpen ?
            <ul className="navigation-user-menu-dropdown" ref={measuredRef}>
                <li>
                    <a href={CONFIG.login_url.replace('/login', '/logout')}>
                        <IconLogout/> Logout
                    </a>
                </li>
            </ul> :
            null
        }
    </div>;
};

export {NavigationUserMenu};
