import './tag.scss';
import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconCopy} from '../../../assets/icons/icon-copy.svg';

function getTextContent(element){
    if(! element){
        return '';
    }
    if(typeof element === 'string'){
        return element;
    }
    const children = element.props && element.props.children;
    if(children instanceof Array){
        return children.map(getTextContent).join('');
    }
    return getTextContent(children);
};

const UITagCopy = function(props){
    const textFull = getTextContent(props.children).trim();
    let text = textFull;
    if(props.truncateLength > 0){
        if(text.length > props.truncateLength + 3){
            text = text.substring(0, Math.ceil(0.5 * props.truncateLength)) + '...' +
                    text.substring(text.length - Math.floor(0.5 * props.truncateLength));
        }
    }

    const copyText = () => {
        navigator.clipboard.writeText(textFull);
    };

    return <span className={'zol-tag-copy' + (props.className ? ' ' + props.className : '')}>
        {text}
        <IconCopy onClick={copyText}/>
    </span>;
};

UITagCopy.defaultProps = {
    className: '',
    truncateLength: -1
};

UITagCopy.propTypes = {
    className: PropTypes.string,
    truncateLength: PropTypes.number
};

export {UITagCopy};
