import React from 'react';
import {PropTypes} from 'prop-types';

function FeedbackError(props){
    return props.error ? <p className="error">{props.error}</p> : null;
}

FeedbackError.propTypes = {
    error: PropTypes.node
};

export {FeedbackError};
