import React from 'react';
import {ViewGridRow} from '../../view/grid/row.js';
import {ViewPanel} from '../../view/panel/panel.js';

function PageLog(){
    return <ViewGridRow className="page-row">
        <h1>Event log</h1>
        <ViewPanel/>
    </ViewGridRow>;
}

export {PageLog};
