import {Model} from '../helpers/model/model.js';
import {loginTriggered} from './use_user.js';
import 'sgts-js';

class ModelUser extends Model {
    static modelName = 'ModelUser';

    static getSelect(input){
        return input;
    }

    constructor(selectData, options = {}){
        super(selectData, {});
        this.cacheLimit = 5;
    }

    getSelect(input){
        return ModelUser.getSelect(input);
    }

    // Only execute a request when method is defined
    fetchData(){
        if(loginTriggered){
            this.setData({});
            return;
        }
        try {
            globalThis.AuthApi.user()
                    .then((data) => {
                        this.setData({user: data});
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setError(err);
                    });
        } catch (err){
            this.setError('Failed loading data in ModelUser: ' + err);
        }
    }
}

export {ModelUser};
