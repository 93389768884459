import {useContext, useState} from 'react';
import {useAsyncAction} from '../async-action/use_async_action.js';
import {deepFreeze} from '../functions/deep_freeze.js';
import {getHash} from '../functions/get_hash.js';
import {ReactCallbackContext} from './react-callback-context.js';

const getObjectValueByPath = function(obj, path){
    const value = {};
    for(const key of Object.keys(obj)){
        if(key === path ||
                (key.length > path.length && key.substring(0, path.length) === path &&
                key.substring(path.length, 1) === '.')){
            value[key] = obj[key];
        }
    }
    return value;
};

const getWatchedState = function(state, watch){
    let returnedState = {};
    if(watch !== null){
        if(! (watch instanceof Array)){
            watch = [watch];
        }
        for(const path of watch){
            const foundItems = getObjectValueByPath(state, path);
            if(Object.keys(foundItems).length === 0){
                // console.warn('Component expects StateContext value for \'' + path +
                //          '\' but no value is set');
                // Instead of throwing this warning, we return a null value for now.
                // TODO: it seems that occasionally when the statecontext structure changes we initially don't
                // have it avaiable. So we watch something that isn't there yet, but it becomes available
                // anyway.
                foundItems[path] = null;
            }
            returnedState = {...returnedState, ...foundItems};
        }
    }else{
        returnedState = state;
    }
    return returnedState;
};

/*  the StateContext should be used as a Map, meaning just key-value pairs, we don't use strucuted tree
    data.

    To filter the state you can set the argument 'watch', which contains a single namespace or an array
    of namespaces, e.g.: useStateContext(['page', 'item_id']).
    @todo for the time being watch only limits the properties returned by this method. It will still trigger
    a render update when any other property is changed

    We can group different values together by using a dot-separated notation. For example if we have
    different filter values that we would like to access all together, but also in isolation, we can store
    it like this:
    {
        'filter.region': 'Africa',
        'filter.country': 'Egypt',
        'filter.year': 2019
    }
    If we use useStateContext we can configure the watched namespace to: 'filter.country', to only get the
    country value. Or we can configure the namespace to: 'filter', to get all properties from this group.
    Note that the returned properties include the 'filter.' part.
*/

const useStateContext = function(watch = null){
    const context = useContext(ReactCallbackContext);
    const [returnState, setReturnState] = useState(getWatchedState(context.currentState, watch));
    const lastState = {...context.currentState};

    useAsyncAction({
        currentHash: null,
        currentState: {},
        callbackId: null,
        onStart: (instance) => {
            instance.callbackId = context.addCallback(
                    (nextState) => {instance.checkUpdate(instance, nextState);});
        },
        onStop: (instance) => {
            if(instance.callbackId){
                context.removeCallback(instance.callbackId);
                instance.callbackId = null;
            }
        },
        checkUpdate: (instance, fullState) => {
            // update the new state reference that's used in the dispatcher
            for(const [key, value] of Object.entries(fullState)){
                lastState[key] = value;
            }
            const nextState = getWatchedState(fullState, watch);
            const hash = getHash(nextState);
            if(hash !== instance.currentHash){
                instance.currentState = nextState;
                instance.currentHash = hash;
                instance.trigger();
            }
        },
        callback: (instance) => {
            // make immutable
            const nextState = {...instance.currentState};
            deepFreeze(nextState);
            setReturnState(nextState);
        }
    });

    const dispatcher = (action, updateContext = true) => {
        context.reducer({state: lastState}, action, updateContext);
    };

    return [returnState, dispatcher];
};

export {useStateContext};
