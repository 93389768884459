import {useModelFeedback} from '../helpers/model/use_model_feedback.js';
import {ModelSgtsJs} from './sgts-js.js';

function useUsers(filter = () => (true)){
    const resultSetSGTS = useModelFeedback(ModelSgtsJs, {method: 'SGTSUserManagementApi.getUsers'});
    const resultSetBU = useModelFeedback(ModelSgtsJs, {method: 'BuUserManagementApi.listBuUsers'});

    if(resultSetBU.feedback || resultSetSGTS.feedback){
        return {
            feedback: resultSetBU.feedback || resultSetSGTS.feedback,
            success: false,
            users: []
        };
    }

    const sgtsUsers = resultSetSGTS.data.map((user) => {
        return {
            ...user,
            name: user.email,
            isAdmin: user.isAdmin,
            role: user.isAdmin ? 'sgts_admin' : 'sgts_user',
            isSGTS: true,
            unit: '-'
        };
    });
    const buUsers = resultSetBU.data.map((user) => {
        return {
            ...user,
            name: user.email,
            unit: user.buName,
            isSGTS: false,
            isAdmin: false
        };
    });
    const users = [...sgtsUsers, ...buUsers]
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .filter(filter);

    return {success: true, users, feedback: null};
}

export {useUsers};
