import React from 'react';
import {PropTypes} from 'prop-types';
import {UIInput} from './input.js';

const UIInputPassword = function(props){
    return <UIInput {...props} type="password"/>;
};

UIInputPassword.defaultValues = {
    value: '',
    hasError: false,
    onChange: () => {},
    onSave: () => {}
};

UIInputPassword.propTypes = {
    name: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    value: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onEnter: PropTypes.func
};

export {UIInputPassword};
