import React, {useEffect} from 'react';
import './input.scss';
import PropTypes from 'prop-types';

const UIInputSelect = function(props){
    const isObject = props.options instanceof Array ? false : true;
    const optionValues = props.options instanceof Array ? props.options : Object.keys(props.options);

    const setValue = (value) => {
        props.onChange(value);
        props.onSave(value);
    };
    // by default select the first value of the list if there is no placeholder defined
    useEffect(() => {
        if(! props.value && ! props.placeholder && optionValues.length > 0){
            setValue(optionValues[0]);
        }
    });

    return <select className={`zol-single-select ${props.hasError ? 'zol-single-error' : ''}`}
            style={props.style}
            value={props.value === null ? '' : props.value}
            onChange={(e) => {setValue(e.target.value);}}>

        <option disabled hidden style={{display: 'none', opacity: .7}} value={''}>
            {props.placeholder}
        </option>

        {optionValues.map((value, i) => (
            <option key={i} value={value}>
                {isObject ? props.options[value] : value}
            </option>
        ))}

    </select>;
};

UIInputSelect.defaultProps = {
    value: '',
    hasError: false,
    onChange: () => {},
    onSave: () => {},
    placeholder: null
};

UIInputSelect.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasError: PropTypes.bool,
    placeholder: PropTypes.string
};

export {UIInputSelect};
