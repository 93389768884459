import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {ViewGridRow} from '../../view/grid/row.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {NavigationBack} from '../../components/navigation/back.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {ViewGridCol} from '../../view/grid/col.js';
import {NavigationBreadcrumb} from '../../components/navigation/breadcrumb.js';
import {UIButtonReject} from '../../components/ui/button/reject.js';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {UIButtonCancel} from '../../components/ui/button/cancel.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUser} from '../../model/use_user.js';
import {WorkflowTransaction} from '../../components/workflow/transaction.js';
import {FeedbackError} from '../../components/feedback/error.js';
import {UITagCopy} from '../../components/ui/tag/copy.js';
import {CONFIG} from '../../config.js';
import {UITagStatus} from '../../components/ui/tag/status.js';
import {UIModal} from '../../components/ui/modal/modal.js';
import {TransactionsExecute} from './execute.js';
import {TransactionsDetails} from './details.js';
import {NavigationLink} from '../../components/navigation/link.js';

function TransactionsItem(props){
    const [isExecutionModalOpen, setIsExecutionModalOpen] = useState(false);
    const user = useUser();
    // We can't get a single transaction item, so we fetch the full list and filter out the right one
    const selectionCriteria = ! user ?
            {} :
            (user?.isSGTS ?
                {method: 'TransactionManagementSgtsApi.listTransactions'} :
                {method: 'TransactionManagementBuApi.listTransactions'}
            );
    const resultSet = useModelFeedback(ModelSgtsJs, selectionCriteria);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const transaction = resultSet.data.find((item) => (item.id === parseInt(props.id)));
    if(! transaction){
        return <FeedbackError error="Transaction not found"/>;
    }

    const transactionAction = async (method) => {
        try {
            const api = user?.isSGTS ? TransactionManagementSgtsApi : TransactionManagementBuApi;
            await api[method](parseInt(props.id));
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
        } catch (err){
            alert(err);
        }
    };

    const actions = [];
    const mayApprove = ! ['inputter', 'approver'].some((role) => (
        transaction[role] && transaction[role] === user?.email
    ));
    if(transaction.status === 'created' && user?.role === 'approve' && mayApprove){
        actions.push(<UIButtonConfirm key="1" onClick={() => {transactionAction('approveTransaction');}}>
            Approve transaction
        </UIButtonConfirm>);
    }
    // TODO: BU input user is not able to cancel, but I think this should be possible as long as the
    // transaction is not approved yet.
    if((transaction.status === 'created' && user?.role === 'input') ||
            (transaction.status === 'approved' && user?.role === 'approve') ||
            (transaction.status === 'sgts_approved' && user?.isSGTS)){
        actions.push(<UIButtonCancel key="2" onClick={() => {transactionAction('cancelTransaction');}}>
            Cancel transaction
        </UIButtonCancel>);
    }
    if(transaction.status === 'approved' && user?.isSGTS && mayApprove){
        actions.push(<UIButtonConfirm key="3" onClick={() => {transactionAction('approveTransaction');}}>
            Approve transaction
        </UIButtonConfirm>);
    }
    if((transaction.status === 'created' && user?.role === 'approve') ||
            (transaction.status === 'approved' && user?.isSGTS)){
        actions.push(<UIButtonReject key="4" onClick={() => {transactionAction('rejectTransaction');}}>
            Reject transaction
        </UIButtonReject>);
    }
    if(transaction.status === 'sgts_approved' && user?.isSGTS){
        actions.push(<UIButtonConfirm onClick={() => {setIsExecutionModalOpen(true);}}>
            Execute transaction
        </UIButtonConfirm>);
    }

    return <>
        <ViewGridRow className="page-row">
            <NavigationBack payload={{id: null, action: null}}/>
            <NavigationBreadcrumb path={[{title: 'Transactions', payload: {id: null}}]}>
                #{transaction.id}: {transaction.description}
            </NavigationBreadcrumb>

        </ViewGridRow>

        <ViewGridRow>
            <ViewGridCol width={67}>
                <ViewPanel>
                    <TransactionsDetails transaction={transaction}/>
                    <dl>
                        <dt>Transaction id:</dt>
                        <dd>#{transaction.id}</dd>
                        <dt>Description:</dt>
                        <dd>{transaction.description}</dd>
                        <dt>From Business unit:</dt>
                        <dd>
                            <NavigationLink payload={{page: 'wallets', id: transaction.buName}}>
                                {transaction.buName}
                            </NavigationLink>
                        </dd>
                        <dt>Amount:</dt>
                        <dd>{transaction.amount} {transaction.tokenId}</dd>
                        <dt>To:</dt>
                        <dd>
                            {CONFIG.blockchainIds[transaction.chainId]}:&nbsp;
                            <UITagCopy>{transaction.addressTo}</UITagCopy>
                        </dd>
                    </dl>
                </ViewPanel>

            </ViewGridCol>
            <ViewGridCol width={33}>
                <ViewPanel className="item-status">
                    <UITagStatus status={transaction.status}/>
                    <h2>Transaction status</h2>

                    <strong>Workflow:</strong>
                    <WorkflowTransaction transaction={transaction}/>

                    <strong>Actions:</strong>
                    {actions.length > 0 ? actions : <em>No actions available</em>}
                </ViewPanel>
            </ViewGridCol>
        </ViewGridRow>

        <UIModal visible={isExecutionModalOpen} title="Execute transaction" width={800} height={450}
                onClose={() => {setIsExecutionModalOpen(false);}}>
            <TransactionsExecute transaction={transaction} onClose={() => {setIsExecutionModalOpen(false);}}/>
        </UIModal>

    </>;
}

TransactionsItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export {TransactionsItem};
