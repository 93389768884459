import React from 'react';
import './header.scss';
import {NavigationMenu} from '../../components/navigation/menu.js';
import {NavigationUserMenu} from '../../components/navigation/user-menu.js';
import {ViewGridRow} from '../grid/row.js';

const ViewHeader = function(props){
    return <div className="header">
        <ViewGridRow>
            <div className="logo">
                SGTS Dashboard
            </div>
            <NavigationMenu/>
            <NavigationUserMenu/>
        </ViewGridRow>
    </div>;
};

export {ViewHeader};
