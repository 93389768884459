
// Makes an object imutable, including all the values. Level is set when called recursively to protect against
// infinite loops caused by reference to self within the object.
const deepFreeze = function(object, level = 0){
    // Retrieve the property names defined on object. This also works with arrays.
    if(typeof object !== 'object' || object === null){
        return;
    }
    const propNames = Object.getOwnPropertyNames(object);

    if(level > 10){
        console.warn('deepFreeze() does not allow objects to go deeper that 10 levels down to prevent ' +
                'infinite recursion.');
    }

    // Freeze properties before freezing self
    for(const name of propNames){
        const value = object[name];

        if(value && typeof value === 'object'){
            deepFreeze(value, level + 1);
        }
    }

    return Object.freeze(object);
};

export {deepFreeze};
