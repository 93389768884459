import React from 'react';
import {PropTypes} from 'prop-types';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUser} from '../../model/use_user.js';
import {useModel} from '../../helpers/model/use_model.js';
import {NavigationLink} from '../../components/navigation/link.js';
import {UITagCopy} from '../../components/ui/tag/copy.js';

const TransactionsCreditorName = function(props){
    const transaction = props.transaction;
    // Payee is identified by wallet, blockchainId and bu_name...
    const creditorId = [transaction.addressTo, transaction.chainId, transaction.buName].join('||');
    const user = useUser();
    const method = (! user || user.isSGTS) ?
            'PayeeManagementApi.sgtsListAllPayees' : 'PayeeManagementApi.buListPayees';
    const resultSet = useModel(ModelSgtsJs, {method});
    let creditor = {id: creditorId};
    if(resultSet.status === ModelSgtsJs.Status.SUCCESS){
        const creditorItem = resultSet.data.find((c) => {
            const id = [c.address, c.chainId, c.bu].join('||');
            return creditorId === id;
        });
        if(creditorItem){
            creditor = {
                ...creditorItem,
                id: creditorId
            };
        }
    }
    if(! creditor.description){
        return <UITagCopy>{transaction.addressTo}</UITagCopy>;
    }

    return props.showLink ?
        <NavigationLink payload={{page: 'creditors', id: creditor.id}}>
            {creditor.description}
        </NavigationLink> :
        creditor.description;
};

TransactionsCreditorName.propTypes = {
    transaction: PropTypes.object.isRequired,
    showLink: PropTypes.bool
};

TransactionsCreditorName.defaultProps = {
    showLink: true
};

export {TransactionsCreditorName};
