import React from 'react';
import {PropTypes} from 'prop-types';
import './loader.css';

const Loader = function(props){
    const borderStyle = Math.round(0.15 * props.size) + 'px solid rgba(' + props.color.join(',') + ',0.2)';
    const style = {
        marginTop: (-0.5 * props.size) + 'px',
        marginLeft: (-0.5 * props.size) + 'px',
        borderTop: Math.round(0.15 * props.size) + 'px solid rgb(' + props.color.join(',') + ')',
        borderRight: borderStyle,
        borderBottom: borderStyle,
        borderLeft: borderStyle,
        width: props.size + 'px',
        height: props.size + 'px'
    };
    const loaderClassName = 'zol-loader-default' +
            (props.fixed ? ' zol-loader-fixed' : '') +
            (props.className ? ` ${props.className}` : '');

    return <div className={loaderClassName} style={{backgroundColor: props.backgroundColor, ...props.style}}>
        <div style={style}/>
        {props.loaderText ?
                <p className={'zol-loader-text'} style={{top: 'calc(50% + ' + (props.size) + 'px)'}}>
                    {props.loaderText}
                </p> :
                null
        }
    </div>;
};
Loader.defaultProps = {
    backgroundColor: 'rgba(255,255,255,0.5)',
    size: 80,
    color: [100, 100, 100]
};

Loader.propTypes = {
    size: PropTypes.number,
    color: PropTypes.arrayOf(PropTypes.number),
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    loaderText: PropTypes.string,
    style: PropTypes.object,
    fixed: PropTypes.bool
};

export {Loader};
