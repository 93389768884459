import React from 'react';
import {PropTypes} from 'prop-types';
import './panel.scss';

function ViewPanel(props){
    return <div className={'zol-view-panel' + (props.className ? ' ' + props.className : '')}>
        {props.children}
    </div>;
}

ViewPanel.propTypes = {
    className: PropTypes.string
};

export {ViewPanel};
