import React from 'react';

const defaultContext = {
    currentIndex: 0,
    callbacks: {},
    addCallback: (callback) => {},
    removeCallback: (index) => {},
    currentState: {},
    reducer: (currentState, action, updateContext) => {}
};

const ReactCallbackContext = React.createContext(defaultContext);
ReactCallbackContext.displayName = 'CallbackContext';

export {ReactCallbackContext};
