import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {ViewGridRow} from '../../view/grid/row.js';
import {UIInputPassword} from '../ui/input/password.js';
import {UIButtonConfirm} from '../ui/button/confirm.js';
import {UIButtonYubikey} from '../ui/button/yubikey.js';
import {ViewGridCol} from '../../view/grid/col.js';
import {FeedbackError} from '../feedback/error.js';

const AuthProtectionSecret = function(props){
    const [passphrase, setPassphrase] = useState('');
    const [error, setError] = useState(null);
    const setYubiKey = async () => {
        try {
            const protection = await globalThis.SGTSUser.yubikeyResolve();
            props.setSecret(protection);
        } catch (err){
            setError('Failed to generate ProtectionSecret. ' + err);
        }
    };
    const submitSecret = async () => {
        if(passphrase){
            try {
                const protection = await globalThis.SGTSUser.passphraseGenerate(passphrase);
                props.setSecret(protection);
            } catch (err){
                setError('Failed to generate ProtectionSecret. ' + err);
            }
        }else{
            setError('Please provide your passphrase');
        }
    };
    return <ViewGridRow className="auth-protection-secret">
        <ViewGridCol width={67}>
            <FeedbackError error={error}/>
            <span>Passphrase:</span><br/><br/>
            <UIInputPassword name="passphrase" value={passphrase} onChange={setPassphrase}/>
        </ViewGridCol>
        <ViewGridCol width={33}>
            <span>Or YubiKey:</span><br/><br/>
            <UIButtonYubikey onChange={setYubiKey}/>
        </ViewGridCol>

        <ViewGridCol width={100}>
            <p className="center-align">
                <UIButtonConfirm onClick={submitSecret}>
                    {props.submitCaption}
                </UIButtonConfirm>
            </p>
        </ViewGridCol>
    </ViewGridRow>;
};

AuthProtectionSecret.propTypes = {
    setSecret: PropTypes.func.isRequired,
    submitCaption: PropTypes.string
};

AuthProtectionSecret.defaultProps = {
    submitCaption: 'Submit'
};

export {AuthProtectionSecret};
