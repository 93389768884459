import React from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconMinusCircle} from '../../../assets/icons/minus-circle-outline.svg';
import {UIButtonIcon} from './icon.js';

function UIButtonReject(props){
    const className = 'zol-ui-button-reject' + (props.className ? ' ' + props.className : '');
    return <UIButtonIcon {...props} Icon={IconMinusCircle} className={className}>
        {props.children}
    </UIButtonIcon>;
}

UIButtonReject.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
};

export {UIButtonReject};
