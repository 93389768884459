
const formatDateTime = function(date){
    if(! (date instanceof Date)){
        date = new Date(date);
    }

    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
            ('0' + date.getDate()).slice(-2) + ' ' +
            ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
};

export {formatDateTime};
