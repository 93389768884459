import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {UIButtonConfirm} from '../../../components/ui/button/confirm.js';
import {FeedbackError} from '../../../components/feedback/error.js';
import {useUser} from '../../../model/use_user.js';
import {UITagCopy} from '../../../components/ui/tag/copy.js';
import {AuthProtectionSecret} from '../../../components/auth/protection-secret.js';
import './keys.scss';
import {UIInput} from '../../../components/ui/input/input.js';
import {ModelSgtsJs} from '../../../model/sgts-js.js';
import {usePublicKeyId} from './use_public_key_id.js';

function UsersKeysActivate(props){
    const user = useUser();
    const [error, setError] = useState(null);
    const [masterKeyId, setMasterKeyId] = useState(props.masterKeyId);
    const [publicKeyId, setPublicKeyId] = useState('');
    const publicKeyIdInSystem = usePublicKeyId(props.publicKey);

    const activateKey = async (protectedSecret) => {
        if(! publicKeyId){
            setError('Please provide the Public Key id you received from the user');
            return;
        }else if(publicKeyId !== publicKeyIdInSystem){
            console.log(props.publicKey);
            setError('The Public Key provided is not valid.');
            return;
        }
        try {
            await globalThis.SGTSUser.encryptMasterKeyForNewUser(user.email, protectedSecret, props.user);
            // getting master key of current user. The master key id for the new user will be the same
            const masterKey = await globalThis.SGTSUser.getMasterKey(user.email, protectedSecret);
            const keyId = await globalThis.KeyDerivation.getMasterKeyId(masterKey);
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
            setMasterKeyId(keyId);
        } catch (err){
            setError('Error activating user. ' + err);
        }
    };

    if(masterKeyId){
        return <div>
            {props.masterKeyId ?
                <p>
                    The Master Key is created for this user.&nbsp;
                    {props.isSigned ?
                            '' : props.user + ' needs to sign the master key after verifying this id. '}
                    The Master Key id shown below can be used to verify the Master Key.
                </p> :
                <p>
                    The master key is created for this user. The user will need to verify the  key id shown
                    below. Please share the key id with the {props.user}. This user can execute transactions
                    after the key id has been verified and signed.
                    <br/><br/>
                </p>
            }
            <div className="center-align">
                <br/><br/>
                <div className="key-box-large">
                    <strong>Master key id:</strong>
                    {props.masterKeyId ?
                        null :
                        <span className="small-note">(Share with {props.user})</span>
                    }
                    <UITagCopy>{masterKeyId}</UITagCopy>
                </div>
            </div>

            <p className="center-align">
                <UIButtonConfirm onClick={props.closeModal}>
                    Got it!
                </UIButtonConfirm>
            </p>
        </div>;
    }

    return <div>
        <p>
            {props.user} has setup a personal keypair. Please verify the id against what {props.user} has
            provided to you. If the ids match you may give this user access to the master key.
            <br/><br/>
        </p>
        <p>Personal key id received from {props.user}</p>
        <p>
            <UIInput name="masterKeyId" value={publicKeyId} onChange={setPublicKeyId}/>
        </p>
        <FeedbackError error={error}/>
        <AuthProtectionSecret submitCaption="Activate access" setSecret={activateKey}/>
    </div>;
}

UsersKeysActivate.propTypes = {
    closeModal: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
    publicKey: PropTypes.string.isRequired,
    masterKeyId: PropTypes.string,
    isSigned: PropTypes.bool
};

export {UsersKeysActivate};
