import './button.scss';
import React from 'react';
import {PropTypes} from 'prop-types';
import colors from '../../../assets/variables.module.scss';

const UIButton = function(props){
    const className = 'zol-ui-button' + (props.className ? ' ' + props.className : '');
    const styleBtn = {...props.style};
    if(props.className.includes('zol-primary')){
        styleBtn.backgroundColor = colors.colorPrimary;
    }

    return <span className={className} onClick={props.onClick} style={styleBtn} title={props.title}>
        {props.children}
    </span>;
};

UIButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    title: PropTypes.string
};

UIButton.defaultProps = {
    className: 'zol-primary',
    onClick: () => {},
    title: null
};

export {UIButton};
