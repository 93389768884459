import './table.scss';
import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {UITableHeader} from './header.js';
import {useSize} from '../../../helpers/use_size.js';
import {useStickyHeader} from './use_sticky_header.js';
import {useTableScroll} from './use_table_scroll.js';
import {orderBy} from '../../../helpers/data-wrangling/order_by.js';
import {TableRowDefault} from './row/default.js';
import {Loader} from '../../../helpers/elements/loader.js';

/*
    TODO: both filter and order should be managed outside of the table component. Often the table
    only reflect a small subset of the total data that is stored on the server and a change in order
    or filter should update a model to fetch new data.
*/
const UITable = function(props){
    const [size, measuredRef] = useSize((w, h) => {return {width: w, height: h};}, {width: 1, height: 1});

    const [orderState, setOrderState] = useState('');
    const order = props.currentOrder ? props.currentOrder : orderState;
    const setOrder = (order) => {
        if(props.setOrder){
            props.setOrder(order);
        }
        setOrderState(order);
    };

    const data = props.data;
    const [isScrolling, setIsScrolling] = useState(false);

    const [tableElement, setTableElement] = useState(null);
    const setMeasuredRef = (node) => {
        measuredRef(node);
        setTableElement(node);
    };

    const [offsetHeader, offsetContent] = useStickyHeader(tableElement, props.stickyHeader);

    const [scrollButtons, scrollOffset] = useTableScroll(tableElement, isScrolling, size.width, offsetHeader);

    // Make a copy of the column object, so we can manipulate the values safely
    const allColumns = props.columns.map((column) => {
        return {
            ...column,
            width: isNaN(column.width) ? 1 : column.width
        };
    });

    // Some columns are not part of the normal row. We exclude these in the header and when calculating table
    // width
    const rowColumns = allColumns.filter((column) => (! column.outOfRow));

    // get the width of the full table.
    const totalWidth = rowColumns.reduce((sum, column) => (sum + column.width), 0);

    // if the window resizes or the columns change, we need to re-evaluate if we need to scroll
    useEffect(() => {
        setIsScrolling(totalWidth > size.width);
    }, [totalWidth, size]);

    if(totalWidth === 0){
        // empty table
        return null;
    }

    // calculate absolute width of each column, scale up to 100% if there's room left
    // first add the width of fixedWidth columns to the tableWidth
    let tableWidth = rowColumns.filter((col) => (col.fixedWidth))
            .reduce((sum, col) => (sum + col.width), 0);
    // scale the remaining columns if required
    const flexCols = rowColumns.filter((col) => (! col.fixedWidth));
    // Note that we are changing the column object directly in below reducer. We made a (shalow) copy of the
    // one in the props. It will keep the props clean, but both rowColumns and allColumns will now have the
    // updated width.
    tableWidth = flexCols.reduce((sum, col, i) => {
        if(! isScrolling && i === flexCols.length - 1){
            // last column gets the remaining width
            col.width = size.width - tableWidth;
        }else if(! isScrolling){
            col.width = Math.round(size.width / totalWidth * col.width);
        }
        return tableWidth += col.width;
    }, tableWidth);

    // Order data when order state is set and no setOrder callback was setup for the table.
    if(order && order.length > 0 && ! props.setOrder){
        const [key, orders] = order.split(' ');
        orderBy(data, key, orders);
    }

    return <div className={'zol-table-container' + (isScrolling ? ' zol-table-scrollable' : '')}
            style={{position: 'relative'}} ref={setMeasuredRef}>
        <table className="zol-table" style={{width: tableWidth}}>
            <UITableHeader order={order}
                    setOrder={setOrder}
                    allowNoOrder={props.allowNoOrder}
                    isScrolling={isScrolling}
                    contentHeight={offsetContent}
                    columns={rowColumns}
                    offsetTop={offsetHeader}
                    scrollOffset={scrollOffset}
                    setFilter={props.setFilter}/>

            <tbody className="zol-table-body">
                {offsetContent > 0 ?
                    // adding 2 spacers instead of one, so it doesn't mess up the odd/even properties
                    [1, 2].map((i) => (<tr key={'spacer' + i} className="zol-table-body-offset">
                        <td style={{height: 0.5 * offsetContent}} colSpan={rowColumns.length}/>
                    </tr>)) :
                    null
                }

                {data.length === 0 || props.isLoading ?
                    <tr>
                        <td colSpan={allColumns.length} style={{textAlign: 'center'}}>
                            {props.isLoading ?
                                <div style={{minHeight: '100px'}}>
                                    <Loader size={35} style={{paddingTop: '40px'}}/>
                                </div> :
                                props.emptyText
                            }
                        </td>
                    </tr> :
                    data.map((row, index) => (
                        <props.rowElement key={index}
                                style={props.rowStyle}
                                index={index}
                                rowData={row}
                                columns={allColumns}
                                scrollOffset={scrollOffset}
                                onClick={props.onRowClick ? () => {props.onRowClick(row);} : null}/>
                    ))
                }
            </tbody>

        </table>

        {scrollButtons}

    </div>;
};

UITable.defaultProps = {
    emptyText: 'No results',
    setFilter: (column, filterValue) => {},
    stickyHeader: false,
    rowElement: TableRowDefault,
    allowNoOrder: false
};

UITable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                // the minimum width of the column. When there is more space the width of all columns will be
                // scaled equally. If the total width is larger than available space we get a scrollbar.
                width: PropTypes.number,
                title: PropTypes.string,
                renderTitle: PropTypes.func,
                parentTitle: PropTypes.string,
                renderParentTitle: PropTypes.func,
                value: PropTypes.func,
                cellStyle: PropTypes.object,
                renderCell: PropTypes.func,
                // when outsideRow: true, this column is not rendered as part of the row. You'll need a custom
                // rowElement to render the content as you like
                outsideRow: PropTypes.bool,
                // enable the ability to sort the results of the table by values of this column. Provide a
                // function to specify a custom sort component in the header
                sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
                filter: PropTypes.bool, // When enable allow filtering results by values in this column
                isFixed: PropTypes.bool, // When enabled fixes the column for horizontal scrolling
                headerStyle: PropTypes.object, // additional styling for the header cell
                // When enabled the headers will rotate, saving some space
                rotateHeader: PropTypes.bool
            })
    ).isRequired,
    emptyText: PropTypes.string,
    rowStyle: PropTypes.object,
    onRowClick: PropTypes.func,
    rowElement: PropTypes.elementType,
    setFilter: PropTypes.func,
    setOrder: PropTypes.func,
    currentOrder: PropTypes.string,
    allowNoOrder: PropTypes.bool,
    stickyHeader: PropTypes.bool,
    isLoading: PropTypes.bool
};

export {UITable};
