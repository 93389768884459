import {useStateContext} from '../state-context.js';
import {compileUrl} from './compile_url.js';

// Returns an function to create a URL based on state values. The limit of this function is that all the url
// values already need to be defined in the current UrlStateContext. Any state values that are not available
// in the UrlStateContext are ignored. Other existing UrlStateContext will stay unchanged
const useCreateUrl = () => {
    const [state] = useStateContext();
    return (newState) => {
        const path = state['url-state-context'].path.map((key) => (
            newState.hasOwnProperty(key) ? newState[key] : (state[key] || null)
        ));
        const query = state['url-state-context'].query.map((key) => (
            newState.hasOwnProperty(key) ? newState[key] : (state[key] || null)
        ));
        const hash = state['url-state-context'].hash.map((key) => (
            newState.hasOwnProperty(key) ? newState[key] : (state[key] || null)
        ));
        return compileUrl({path, query, hash});
    };
};

export {useCreateUrl};
