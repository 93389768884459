import React from 'react';
import {PropTypes} from 'prop-types';
import {ViewGridRow} from '../../view/grid/row.js';
import {ViewGridCol} from '../../view/grid/col.js';
import {TransactionsCreditorName} from './creditor-name.js';
import {UITagTransaction} from '../../components/ui/tag/transaction.js';
import {NavigationLink} from '../../components/navigation/link.js';
import {useWalletBalance} from '../../model/use_wallet_balance.js';

function TransactionsDetails(props){
    // TODO: replace this with the moralis implementation in CloudWallet
    const balance = useWalletBalance(props.transaction.addressFrom, props.transaction.chainId, true);

    return <div className="transaction-details">
        <ViewGridRow>
            <ViewGridCol width={33}>
                <p className="right-align">
                    <NavigationLink payload={{page: 'wallets', id: props.transaction.buName}}>
                        {props.transaction.buName}
                    </NavigationLink>
                </p>
                <span className="transaction-details-balance">Current balance: {balance}</span>
            </ViewGridCol>
            <ViewGridCol width={33}>
                <UITagTransaction type="out" amount={props.transaction.amount}
                        currency={props.transaction.tokenId}/>
            </ViewGridCol>
            <ViewGridCol width={33}>
                <p>
                    <TransactionsCreditorName transaction={props.transaction}/>
                </p>
            </ViewGridCol>
        </ViewGridRow>
    </div>;
}

TransactionsDetails.propTypes = {
    transaction: PropTypes.object.isRequired
};

export {TransactionsDetails};
