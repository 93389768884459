import React from 'react';
import {PropTypes} from 'prop-types';
import {TableCellDefault} from '../cell/default.js';

const TableRowDefault = function(props){
    const oddEvenClass = props.index % 2 === 0 ? 'zol-odd' : 'zol-even';
    const className = 'zol-table-row ' + (props.className ? props.className + ' ' : '') + oddEvenClass;
    const styleRow = {
        cursor: props.onClick ? 'pointer' : 'default',
        ...props.style
    };

    // Not all columns are part of the row, but will be rendered in a special way. We'll ignore those here.
    const rowColumns = props.columns.filter((column) => (! column.outOfRow));

    return <tr className={className} style={styleRow}
            onClick={() => (props.onClick ? props.onClick(props.rowData) : null)}>

        {rowColumns.map((column, colIndex) => (
            <props.cellElement key={colIndex}
                    index={colIndex}
                    rowIndex={props.index}
                    settings={column}
                    rowData={props.rowData}
                    renderProps={props.cellRenderProps}
                    scrollOffset={props.scrollOffset}/>
        ))}

    </tr>;
};

TableRowDefault.defaultProps = {
    style: {},
    cellElement: TableCellDefault,
    scrollOffset: 0,
    cellRenderProps: {}
};

TableRowDefault.propTypes = {
    style: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    index: PropTypes.number.isRequired,
    rowData: PropTypes.object.isRequired,
    cellElement: PropTypes.elementType,
    cellRenderProps: PropTypes.object,
    columns: PropTypes.array.isRequired,
    scrollOffset: PropTypes.number,
    className: PropTypes.string
};

export {TableRowDefault};
