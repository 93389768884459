import React from 'react';
import {PropTypes} from 'prop-types';
import './grid.scss';
import {ViewGridCol} from './col.js';

const ViewGridRow = function(props){
    const className = 'zol-grid-row' + (props.className ? ' ' + props.className : '');
    let otherElements = [];
    return <div className={className}>
        {props.children instanceof Array ?
            props.children.map((node, index) => {
                if(node?.type !== ViewGridCol){
                    if(! (node instanceof String) || node.trim() !== ''){
                        otherElements.push(node);
                    }
                }else{
                    const elements = [...otherElements];
                    otherElements = [];
                    return <React.Fragment key={index}>
                        {elements.length > 0 ?
                            <ViewGridCol width={100} key={index}>{elements}</ViewGridCol> :
                            null
                        }
                        {node}
                    </React.Fragment>;
                }
                return null;
            }) :
            (props.children?.type !== ViewGridCol ?
                <ViewGridCol width={100}>{props.children}</ViewGridCol> :
                props.children
            )
        }
        {otherElements.length > 0 ? <ViewGridCol width={100}>{otherElements}</ViewGridCol> : null}
    </div>;
};

ViewGridRow.propTypes = {
    className: PropTypes.string
};

ViewGridRow.defaultProps = {
    className: ''
};

export {ViewGridRow};
