import React from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {UsersList} from './list.js';
import {UsersEdit} from './edit.js';
import {UsersItem} from './item.js';

function PageUsers(){
    const [state] = useStateContext(['page', 'id', 'action']);
    if(state.page !== 'users'){
        return null;
    }

    if(state.action === 'new'){
        return <UsersEdit type="new"/>;
    }else if(state.action === 'edit' && state.id){
        return <UsersEdit type="edit" id={state.id}/>;
    }else if(state.action === 'delete' && state.id){
        return <UsersEdit type="delete" id={state.id}/>;
    }else if(state.id){
        return <UsersItem id={state.id}/>;
    }
    return <UsersList/>;
}

export {PageUsers};
