
const getSimpleReducer = function(keys){
    return (state, action) => {
        if(action.type === 'setValues'){
            // this sets multiple values at once
            const nextState = {...state};
            let keysFound = 0;
            for(const key of Object.keys(action.value)){
                if(keys.includes(key)){
                    nextState[key] = action.value[key];
                    keysFound++;
                }
            }
            if(keysFound === 0){
                // keys not found, this is not the right reducer
                return state;
            }
            if(keysFound !== Object.keys(action.value).length){
                console.warn('Not all properties provided in the setValues state update could be found in ' +
                        'the same StateContext. It\'s not possible to update values from different ' +
                        'contexts using setValues.');
                const reducer = getSimpleReducer(Object.keys(action.value));
                return reducer(state, action);
            }
            return nextState;
        }
        if(keys.indexOf(action.type) !== -1){
            // if something changes, always create a new object
            const nextState = {};
            nextState[action.type] = action.value;
            return {
                ...state,
                ...nextState
            };
        }
        // by default return the same object: nothing changes
        return state;
    };
};

export {getSimpleReducer};
