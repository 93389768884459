import React from 'react';
import {PropTypes} from 'prop-types';
import {formatDateTime} from '../../helpers/format/date_time.js';
import './workflow.scss';
import {NavigationLink} from '../navigation/link.js';

const steps = {
    created: 'Created',
    approved: 'Approved',
    sgts_approved: 'SGTS approved',
    executed: 'Executed',
    canceled: 'Cancelled',
    rejected: 'Rejected'
};

const pendingAction = {
    approved: 'BU Approver',
    sgts_approved: 'SGTS Approval',
    executed: 'SGTS Execution'
};

const timestampKeys = {
    created: 'creationDate',
    approved: 'buApprovalDate',
    sgts_approved: 'sgtsApprovalDate',
    executed: 'executionDate',
    canceled: 'cancellationDate',
    rejected: 'rejectionDate'
};

const WorkflowTransaction = function(props){
    const t = props.transaction;
    const stepsInUse = {...steps};
    // We either show rejected/cancelled or the approval steps
    if(t.status === 'rejected' || t.status === 'canceled'){
        // keep all steps that were already approved and delete the rest
        for(const s of Object.keys(steps)){
            if(t[timestampKeys[s]] === null){
                delete stepsInUse[s];
            }
        }
    }
    if(t.status !== 'rejected'){
        delete stepsInUse.rejected;
    }
    if(t.status !== 'canceled'){
        delete stepsInUse.canceled;
    }
    const currentLevel = ['executing', 'executed'].includes(t.status) ? 3 :
            Object.keys(stepsInUse).indexOf(t.status);

    return <div className="workflow workflow-transaction">
        {Object.keys(stepsInUse).map((status, index) => {
            const isActive = index <= currentLevel;
            const isActiveBefore = index - 1 <= currentLevel;
            const isActiveAfter = index + 1 <= currentLevel;
            const activeClass = (isActive ? ' is-active' : '') + (isActiveBefore ? ' is-active-before' : '') +
                    (isActiveAfter ? ' is-active-after' : '');
            const executor = t.executions.length > 0 ? t.executions[t.executions.length - 1].executor : null;
            const exStatus = t.executions.length > 0 ? t.executions[t.executions.length - 1].status : null;
            const users = {
                0: t.inputter,
                1: t.approver,
                2: t.sgtsApprover,
                3: executor
            };
            const timestamp = isActive ? t[timestampKeys[status]] : null;
            const userId = ['rejected', 'canceled'].includes(status) ? null : (users[index] || null);
            return <div key={status} className={'workflow-step' + (index + 1) + activeClass}>
                <h4>{stepsInUse[status]}{index === 3 && exStatus ? ' (' + exStatus + ')' : ''}</h4>
                {isActive ?
                    (userId ?
                        <NavigationLink payload={{page: 'users', id: userId}}>{userId}</NavigationLink> :
                        null
                    ) :
                    <h3>{pendingAction[status]}</h3>
                }
                {timestamp ? <span>{formatDateTime(timestamp)}</span> : <span/>}
            </div>;
        })}
    </div>;
};

WorkflowTransaction.propTypes = {
    transaction: PropTypes.object.isRequired
};

export {WorkflowTransaction};
