import React, {useEffect} from 'react';
import {ViewMain} from './view/main.js';
import {useStateContext} from './helpers/state-context.js';
import {ViewFooter} from './view/footer/footer.js';
import {ViewHeader} from './view/header/header.js';
import {SizedApp} from './helpers/elements/sized-app.js';
import {sitemap} from './sitemap.js';
import {useNotifications} from './components/notification/use_notifications.js';
import {ModelSgtsJs} from './model/sgts-js.js';
import {ModelUser} from './model/user.js';
import {CONFIG} from './config.js';

function App(){
    const [state] = useStateContext(['page']);
    const notifications = useNotifications();

    const refreshData = async () => {
        const user = await globalThis.AuthApi.user();
        if(user){
            // user is still logged in, refresh the data
            ModelSgtsJs.invalidateAllInstances([ModelSgtsJs]);
        }else{
            // user session is lost. Refresh the user model, which will trigger the login
            ModelUser.invalidateAllInstances([ModelUser]);
        }
    };

    useEffect(() => {
        // Refresh data whenever the page changes
        refreshData();
    }, [state.page]);
    useEffect(() => {
        // Polling for data every 10 seconds. This may put significant extra load on the API.
        const timerId = setInterval(() => {
            refreshData();
        }, CONFIG.dataRefreshTime * 1000);
        return () => {
            clearInterval(timerId);
        };
    }, []);

    const selectedPage = sitemap.find((page) => (page.id === state.page));
    const currentPage = selectedPage ? selectedPage.component : sitemap[0].component;

    return <SizedApp>
        <ViewHeader/>
        <ViewMain>
            {notifications}
            {currentPage}
        </ViewMain>
        <ViewFooter/>
    </SizedApp>;
}

export {App};
