import './tag.scss';
import React from 'react';
import PropTypes from 'prop-types';

const UITagTransaction = function(props){
    return <div className={'zol-tag-transaction zol-tag-transaction-' + props.type}>
        <span className="zol-tag-transaction-arrow"/>
        <span className="zol-tag-transaction-amount">
            {props.amount} {props.currency}
        </span>
    </div>;
};

UITagTransaction.defaultProps = {
    type: 'out'
};

UITagTransaction.propTypes = {
    type: PropTypes.oneOf(['in', 'out']),
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currency: PropTypes.string.isRequired
};

export {UITagTransaction};
