import React, {useState, useEffect} from 'react';
import {UIModal} from '../../../components/ui/modal/modal.js';
import {UsersKeysActivate} from './activate.js';
import {AuthProtectionSecret} from '../../../components/auth/protection-secret.js';
import {useUser} from '../../../model/use_user.js';
import {FeedbackError} from '../../../components/feedback/error.js';

function useUsersKeysActivate(){
    const [activateUser, setActivateUser] = useState(null);
    const [showKeyUser, setShowKeyUser] = useState(null);
    const [protection, setProtection] = useState(null);
    const [keyId, setKeyId] = useState(null);
    const [error, setError] = useState(null);
    const user = useUser();

    const getMasterKey = async (user, protection) => {
        setError(null);
        try {
            const masterKey = await globalThis.SGTSUser.getMasterKey(user.email, protection);
            const id = await globalThis.KeyDerivation.getMasterKeyId(masterKey);
            setKeyId(id);
        } catch (e){
            setError('Error getting key. Perhaps you used a wrong passphrase.');
            setProtection(null);
        }
    };
    useEffect(() => {
        // The master key id of all users is the same, but we need the signed master key to create the id
        // we use the current user for that reason to get the master key id.
        if(protection && user && ! keyId){
            getMasterKey(user, protection);
        }
    }, [protection, showKeyUser]);

    let component = null;
    if(activateUser !== null){
        component = <UIModal visible={true} title={'Activate user access'} width={800} height={450}
                onClose={() => {setActivateUser(null);}}>
            <UsersKeysActivate user={activateUser.email} publicKey={activateUser.publicKey}
                    closeModal={() => {setActivateUser(null);}}/>
        </UIModal>;
    }else if(showKeyUser !== null){
        component = <UIModal visible={true} title={'Master Key id'} width={800} height={450}
                onClose={() => {setShowKeyUser(null);}}>
            <FeedbackError error={error}/>
            {protection === null ?
                <AuthProtectionSecret setSecret={setProtection} submitCaption="Continue"/> :
                null
            }
            {keyId !== null ?
                <UsersKeysActivate closeModal={() => {setShowKeyUser(null);}} user={showKeyUser.email}
                        publicKey={showKeyUser.publicKey} masterKeyId={keyId}
                        isSigned={showKeyUser.signedMasterKey !== null}/> :
                null
            }
        </UIModal>;
    }

    return [component, setActivateUser, setShowKeyUser];
};

export {useUsersKeysActivate};
