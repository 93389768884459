import React from 'react';
import {PropTypes} from 'prop-types';
import {UITagCopy} from '../../../components/ui/tag/copy.js';
import {usePublicKeyId} from './use_public_key_id.js';

const PublicKeyId = function(props){
    const publicKeyId = usePublicKeyId(props.publicKey);

    if(publicKeyId === '-' || publicKeyId === '...'){
        return publicKeyId;
    }

    return <UITagCopy truncateLength={props.truncateLength}>{publicKeyId}</UITagCopy>;
};

PublicKeyId.propTypes = {
    publicKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    truncateLength: PropTypes.number
};

PublicKeyId.defaultProps = {
    truncateLength: 8
};

export {PublicKeyId};
