import React from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {CreditorsList} from './list.js';
import {CreditorsEdit} from './edit.js';
import {CreditorsItem} from './item.js';

function PageCreditors(){
    const [state] = useStateContext(['page', 'id', 'action']);
    if(state.page !== 'creditors'){
        return null;
    }

    let component = <CreditorsList/>;
    if(state.action === 'new'){
        component = <CreditorsEdit type="new"/>;
    }else if(['edit', 'delete'].includes(state.action) && state.id){
        component = <CreditorsEdit type={state.action} id={state.id}/>;
    }else if(state.id && typeof state.id == 'string'){
        component = <CreditorsItem id={state.id}/>;
    }
    return component;
}

export {PageCreditors};
