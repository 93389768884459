import React from 'react';
import {PropTypes} from 'prop-types';
import {ViewGridRow} from '../../view/grid/row.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {NavigationBack} from '../../components/navigation/back.js';
import {UITable} from '../../components/ui/table/table.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {ViewGridCol} from '../../view/grid/col.js';
import {NavigationBreadcrumb} from '../../components/navigation/breadcrumb.js';
import {UIButtonReject} from '../../components/ui/button/reject.js';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUser} from '../../model/use_user.js';
import {WorkflowCreditor} from '../../components/workflow/creditor.js';
import {transactionsTableColumns} from '../transactions/list.js';
import {UITagStatus} from '../../components/ui/tag/status.js';
import {NavigationItemEdit} from '../../components/navigation/item-edit.js';
import {UITagCopy} from '../../components/ui/tag/copy.js';
import {useStateContext} from '../../helpers/state-context.js';

function CreditorsItem(props){
    const user = useUser();
    const [, dispatch] = useStateContext(['id']);
    const [walletAddress, blockchainId, unit] = props.id.split('||');
    const resultSetCreditors = useModelFeedback(ModelSgtsJs,
            {
                method: 'PayeeManagementApi.getPayee',
                params: [walletAddress, parseInt(blockchainId), unit]
            });
    const creditor = resultSetCreditors.data;

    const selectionCriteria = ! user ?
            {} :
            (user.isSGTS ?
                {method: 'TransactionManagementSgtsApi.listTransactions'} :
                {method: 'TransactionManagementBuApi.listTransactions'});
    const resultSetTransaction = useModelFeedback(ModelSgtsJs, selectionCriteria);
    const transactions = resultSetTransaction.feedback ? [] : resultSetTransaction.data.filter((t) => (
        t.addressTo === walletAddress && t.chainId === parseInt(blockchainId) && t.buName === unit
    ));

    if(resultSetCreditors.feedback){
        return resultSetCreditors.feedback;
    }

    const buApprove = async () => {
        try {
            await PayeeManagementApi.buApprovePayee(walletAddress, parseInt(blockchainId), unit);
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
        } catch (err){
            alert(err);
        }
    };
    const sgtsApprove = async () => {
        try {
            await PayeeManagementApi.sgtsApprovePayee(walletAddress, parseInt(blockchainId), unit);
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
        } catch (err){
            alert(err);
        }
    };
    const sgtsReject = async () => {
        try {
            await PayeeManagementApi.sgtsRejectPayee(walletAddress, parseInt(blockchainId), unit);
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
        } catch (err){
            alert(err);
        }
    };
    const deleteItem = async () => {
        try {
            await PayeeManagementApi.deletePayee(walletAddress, parseInt(blockchainId), unit);
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
            dispatch({type: 'id', value: null});
        } catch (err){
            alert(err);
        }
    };

    const actions = [];
    const mayApprove = ! ['createdBy', 'buApprovedBy'].some((role) => (
        creditor[role] && creditor[role] === user?.email
    ));
    if(creditor.status === 'created' && user?.role !== 'input' && mayApprove){
        actions.push(<UIButtonConfirm key="1" onClick={buApprove}>Approve creditor</UIButtonConfirm>);
    }
    if(creditor.status === 'approved' && user?.isSGTS && mayApprove){
        actions.push(<UIButtonConfirm key="2" onClick={sgtsApprove}>Approve creditor</UIButtonConfirm>);
    }
    if(creditor.status === 'approved' && user?.isSGTS){
        actions.push(<UIButtonReject key="3" onClick={sgtsReject}>Reject creditor</UIButtonReject>);
    }else if((user?.role === 'approve' && ['created', 'approved'].includes(creditor.status)) ||
            (user?.isSGTS && ['created', 'sgts_approved', 'rejected'].includes(creditor.status))){
        actions.push(<UIButtonReject key="3" onClick={deleteItem}>Delete creditor</UIButtonReject>);
    }

    return <>
        <ViewGridRow className="page-row">
            <NavigationBack payload={{id: null, action: null}}/>
            <NavigationBreadcrumb path={[{title: 'Creditors', payload: {id: null}}]}>
                {creditor.description}
            </NavigationBreadcrumb>

        </ViewGridRow>

        <ViewGridRow>
            <ViewGridCol width={67}>
                <ViewPanel>
                    {user?.isSGTS ? <NavigationItemEdit id={props.id} includeEdit={false}/> : null}
                    <dl>
                        <dt>Name:</dt>
                        <dd>{creditor.description}</dd>
                        <dt>Account #:</dt>
                        <dd><UITagCopy>{creditor.address}</UITagCopy></dd>
                        <dt>Business unit:</dt>
                        <dd>{creditor.bu}</dd>
                    </dl>
                </ViewPanel>

            </ViewGridCol>
            <ViewGridCol width={33}>
                <ViewPanel className="item-status">
                    <UITagStatus status={creditor.status} type="creditor"/>
                    <h2>Creditor status</h2>

                    <strong>Workflow:</strong>
                    <WorkflowCreditor item={creditor}/>

                    <strong>Actions:</strong>
                    {actions.length ? actions : <em>No actions available</em>}
                </ViewPanel>
            </ViewGridCol>
        </ViewGridRow>

        <ViewGridRow>
            <ViewGridCol width={100}>
                <ViewPanel className="panel-table">
                    <h2>Transactions</h2>
                    {resultSetTransaction.feedback ?
                        resultSetTransaction.feedback :
                        <UITable data={transactions} columns={transactionsTableColumns}/>
                    }
                </ViewPanel>
            </ViewGridCol>
        </ViewGridRow>
    </>;
}

CreditorsItem.propTypes = {
    id: PropTypes.string.isRequired
};

export {CreditorsItem};
