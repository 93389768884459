import {useState, useEffect} from 'react';

const usePublicKeyId = function(publicKey){
    const [publicKeyId, setPublicKeyId] = useState('...');

    useEffect(() => {
        if(publicKey === '-'){
            setPublicKeyId('-');
            return;
        }
        const getKeyId = async () => {
            try {
                const keyId = typeof publicKey === 'string' ?
                    await globalThis.KeyDerivation.publicKeyIdFromHex(publicKey) :
                    await globalThis.KeyDerivation.publicKeyId(publicKey);
                setPublicKeyId(keyId);
            } catch (err){
                console.error(err);
                setPublicKeyId('-');
            }
        };
        getKeyId();
    }, [publicKey]);

    return publicKeyId;
};

export {usePublicKeyId};
